import { Modal } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPopupChildren, togglePopup } from '../../redux/globals/actions';
import './styles.css';

function ModalPopup() {
  const { showPopup, popupChildren } = useSelector((state) => state.globals);
  const dispatch = useDispatch();

  useEffect(() => {
    if (showPopup === false) {
      dispatch(setPopupChildren(null));
    }
  }, [showPopup]);

  const onClose = () => {
    dispatch(togglePopup(false));
  };
  return (
    <Modal
      centered
      open={showPopup}
      onCancel={onClose}
      // type={color ? type : false}
      footer={<></>}
    >
      {popupChildren}
    </Modal>
  );
}

export default ModalPopup;
