import { Form, Col, Row, Input, Divider, Button, message } from 'antd';
import React, { useState, useCallback, useReducer, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getItem } from '../../../../utility/localStorageControl';
import { setDrawer, setPopupChildren } from '../../../../redux/globals/actions';
import axios from 'axios';
import { getToken } from '../../../../utility/helpers';
import PreviewReport from './PreviewReport';
import { togglePopup } from '../../../../redux/globals/actions';

const Reports = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();

  const initialState = {
    examInfo: '',
    findings: '',
    recommendation: '',
    impressions: '',
    phone: '',
    email: '',
    practitionerEmail: '',
    name: '',
    signature: '',
    studyInfo: '',
  };

  const [state, stateDispatch] = useReducer((prevState, value) => ({ ...prevState, ...value }), initialState);
  const myData = useSelector((state) => state.user.myData);
  const identity = getItem('identity');

  const [form] = Form.useForm();

  useEffect(() => {
    if (myData.practitioner) {
      form.setFieldsValue({
        name: (myData?.firstName && myData.firstName) + ' ' + (myData?.lastName && myData.lastName),
      });

      form.setFieldsValue({
        practitionerEmail: myData?.email,
      });

      stateDispatch({
        name: (myData?.firstName && myData.firstName) + ' ' + (myData?.lastName && myData.lastName),
      });
      stateDispatch({ email: state?.email || '' });
      stateDispatch({ practitionerEmail: myData?.email || '' });
    } else {
      stateDispatch({
        name: (myData?.adminFirstName && myData.adminFirstName) + ' ' + (myData?.adminLastName && myData.adminLastName),
      });
      stateDispatch({
        name: (myData?.adminFirstName && myData.adminFirstName) + ' ' + (myData?.adminLastName && myData.adminLastName),
      });
    }

    if (identity === 'practitioner') {
      stateDispatch({ signature: myData?.practitioner.signature || '' });
    } else {
      stateDispatch({ signature: myData?.practitioner.signature || '' });
    }

    if (location?.state) {
      stateDispatch({ studyInfo: location?.state || '' });
    }
  }, [myData]);

  useEffect(() => {}, [state]);

  const [loading, setLoading] = useState(false);

  const client = axios.create({
    baseURL: 'https://server.xolanihealth.cloud/api/v1',
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
    },
  });

  const onHandleSubmitReport = useCallback(
    (values) => {
      setLoading(true);
      client
        .post(`/practitioner/studies/${location?.state?.study?.id}/dicomx/report`, { ...values })
        .then(({ data }) => {
          console.log(data);
          message.success(data?.message);
          dispatch(setDrawer(false));
        })
        .catch((error) => {
          console.log(error);
          message.error(error?.errors || error?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [history, dispatch],
  );

  const handlePreview = () => {
    dispatch(togglePopup(true));
    dispatch(setPopupChildren(<PreviewReport values={state} />));
  };

  return (
    <>
      <Divider orientation="center" className="text-gray-500">
        Write your Report
      </Divider>

      <Form layout="vertical" className="w-50" form={form} requiredMark onFinish={onHandleSubmitReport}>
        <Row gutter={16}>
          <Col lg={24} sm={24} xs={24}>
            <Form.Item
              name="examInfo"
              label="Exam information"
              className="[&>div>div>label]:text-[13px] [&>div>div>label]:text-gray-500 dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium"
              rules={[
                {
                  required: true,
                  message: 'please enter an exam information',
                },
              ]}
            >
              <Input.TextArea
                rows={4}
                value={state?.examInfo}
                onChange={(e) => stateDispatch({ examInfo: e.target.value })}
                placeholder="please enter an exam information"
              />
            </Form.Item>
          </Col>
          <Col lg={24} sm={24} xs={24}>
            <Form.Item
              name="findings"
              label="Findings"
              className="[&>div>div>label]:text-[13px] [&>div>div>label]:text-gray-500 dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium"
              rules={[
                {
                  required: true,
                  message: 'please enter an exam findings',
                },
              ]}
            >
              <Input.TextArea
                rows={4}
                placeholder="please enter an exam findings"
                value={state?.findings}
                onChange={(e) => stateDispatch({ findings: e.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col lg={24} sm={24} xs={24}>
            <Form.Item
              name="recommendations"
              label="Recommendations"
              className="[&>div>div>label]:text-[13px] [&>div>div>label]:text-gray-500 dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium"
              rules={[
                {
                  required: true,
                  message: 'please enter your recommendations',
                },
              ]}
            >
              <Input.TextArea
                rows={4}
                placeholder="please enter your recommendations"
                className="[&>div>div>label]:text-[13px] [&>div>div>label]:text-gray-500 dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium"
                value={state?.recommendations}
                onChange={(e) => stateDispatch({ recommendations: e.target.value })}
              />
            </Form.Item>
          </Col>

          <Col lg={24} sm={24} xs={24}>
            <Form.Item
              name="impressions"
              label="Impressions"
              className="[&>div>div>label]:text-[13px] [&>div>div>label]:text-gray-500 dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium"
            >
              <Input.TextArea
                rows={4}
                placeholder="Impressions"
                value={state?.impressions}
                onChange={(e) => stateDispatch({ impressions: e.target.value })}
              />
            </Form.Item>
          </Col>
          <Col lg={24} sm={24} xs={24}>
            <Form.Item
              name="practitionerEmail"
              label="practitionerEmail"
              hidden
              className="[&>div>div>label]:text-[13px] [&>div>div>label]:text-gray-500 dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium"
            >
              <Input
                value={state?.practitionerEmail}
                onChange={(e) => stateDispatch({ practitionerEmail: e.target.value })}
              />
            </Form.Item>

            <Form.Item
              name="email"
              label="Recipient Email"
              className="[&>div>div>label]:text-[13px] [&>div>div>label]:text-gray-500 dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium"
              rules={[
                {
                  required: true,
                  message: 'Please enter recipient Email email',
                  type: 'email',
                },
              ]}
            >
              <Input
                value={state?.email}
                onChange={(e) => stateDispatch({ email: e.target.value })}
                placeholder="Please enter recipient email"
              />
            </Form.Item>

            <Form.Item
              name="name"
              label="Name"
              initialValue={state?.name}
              hidden
              className="[&>div>div>label]:text-[13px] [&>div>div>label]:text-gray-500 dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium"
            >
              <Input value={state?.name} onChange={(e) => stateDispatch({ name: e.target.value })} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16} className="flex flex-row">
          <Col>
            <Button
              className="bg-primary hover:bg-white hover:text-primary border-solid border-1 border-primary text-white dark:text-white87 text-[14px] font-semibold leading-[22px] inline-flex items-center justify-center rounded-full px-[20px] py-[5px]"
              type="primary"
              size="large"
              htmlType="submit"
              loading={loading}
            >
              {loading ? 'Submiting...' : 'Submit report'}
            </Button>
          </Col>
          <Col>
            <Button
              className="bg-primary hover:bg-white hover:text-primary border-solid border-1 border-primary text-white dark:text-white87 text-[14px] font-semibold leading-[22px] inline-flex items-center justify-center rounded-full px-[20px] py-[5px]"
              type="primary"
              size="large"
              onClick={() => handlePreview()}
            >
              Preview
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Reports;
