import { SET_MY_STUDIES, SET_STUDIES, SET_TOTAL_STUDIES } from './types';

const initialState = {
  studies: [],
  myStudies: [],
  total: '',
};

const studiesReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SET_STUDIES:
      return {
        ...state,
        studies: data,
      };
    case SET_MY_STUDIES:
      return {
        ...state,
        myStudies: data,
      };
    case SET_TOTAL_STUDIES:
      return {
        ...state,
        total: data,
      };

    default:
      return state;
  }
};

export default studiesReducer;
