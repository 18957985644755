import Styled from 'styled-components';

const AuthenticationWrap = Styled.div`
  
`;

const Content = Styled.div`
    
`;

const AuthFormWrap = Styled.div`
  
  
`;

export { AuthenticationWrap, Content, AuthFormWrap };
