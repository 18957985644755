import env from '../env';
import { request } from './api';
const { API_URL2 } = env;

const URL = {
  getInstitutions: `${API_URL2}/admin/institutions`,
  getMe: `${API_URL2}/institution/me`,
  getDoctors: `${API_URL2}/institution/doctors`,
  confirmInvites: `${API_URL2}/institution/doctors/accept`,
  addRegular: `${API_URL2}/institution/regulars`,
  getStudies: `${API_URL2}/institution/studies`,
  getDashboard: `${API_URL2}/institution/dashboard`,
  getRegulars: `${API_URL2}/institution/regulars`,
  getTransactions: `${API_URL2}/institution/transactions`,
  importRegulars: `${API_URL2}/institution/regulars/upload`,
  importDoctors: `${API_URL2}/institution/doctors/upload`,
  suspendDoctors: `${API_URL2}/institution/doctors`,
};

function getInstitutions() {
  return request(URL.getInstitutions, {}, 'GET');
}
function getMe() {
  return request(URL.getMe, {}, 'GET');
}
function getDoctors() {
  return request(URL.getDoctors, {}, 'GET');
}
function addDoctors(params) {
  return request(URL.getDoctors, { ...params }, 'POST');
}
function confirmInvites(body) {
  return request(URL.confirmInvites, body, 'POST');
}
function addRegular(param) {
  return request(URL.addRegular, { ...param }, 'POST');
}
function getInstitutionStudy(param) {
  return request(`${URL.getStudies}?institutionId=${param}`, {}, 'GET');
}
function getInstitutionDashboard(param) {
  return request(`${URL.getDashboard}?institutionId=${param}`, {}, 'GET');
}
function getRegulars(param) {
  return request(`${URL.getRegulars}?institutionId=${param}`, {}, 'GET');
}
function getTransactions(param) {
  return request(`${URL.getTransactions}?institutionId=${param}`, {}, 'GET');
}

function importRegulars(data) {
  return request(URL.importRegulars, data, 'POST', {}, { isFormData: true });
}
function importDoctors(data) {
  return request(URL.importDoctors, data, 'POST', {}, { isFormData: true });
}

function suspendDoctor({ id, status }) {
  return request(`${URL.suspendDoctors}/${id}/suspend`, { status }, 'PATCH');
}

const institutionService = {
  getInstitutions,
  getMe,
  getDoctors,
  addDoctors,
  confirmInvites,
  addRegular,
  getInstitutionStudy,
  getInstitutionDashboard,
  getRegulars,
  getTransactions,
  importRegulars,
  importDoctors,
  suspendDoctor,
};

export default institutionService;
