import io from 'socket.io-client';
const SOCKET_URL = 'https://server.xolanihealth.cloud';
class SocketService {
  constructor() {
    this.socket = null;
  }

  connect(token) {
    if (!this.socket) {
      this.socket = io.connect(SOCKET_URL, {
        query: { token },
        transports: ['websocket', 'polling'],
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
      });

      this.socket.on('reconnect', () => {
        console.log('Reconnected to socket server');
      });

      this.socket.on('reconnect_attempt', () => {
        console.log('Attempting to reconnect...');
      });

      this.socket.on('reconnect_error', (error) => {
        console.error('Socket reconnection error:', error);
      });

      this.socket.on('reconnect_failed', () => {
        console.error('Socket reconnection failed');
      });
      this.socket.on('connect', () => {
        this.isConnected = true;
        console.log('Connected to socket server');
      });
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }
  isConnected() {
    return this.socket && this.socket.connected;
  }
  on(event, callback) {
    if (this.socket) {
      this.socket.on(event, callback);
    }
  }
  off(event, callback) {
    if (this.socket) {
      this.socket.off(event, callback);
    }
  }

  emit(event, data, callback) {
    if (this.socket) {
      this.socket.emit(event, data, callback);
    }
  }
}

const socketService = new SocketService();
export default socketService;
