import { Peer } from "peerjs";

class PeerService {
  constructor() {
    this.peer = null;
    this.localStream = null;
  }

  async init() {
    this.peer = new Peer({ debug: true });

    this.peer.on("open", (id) => {
      console.log("PeerJS connection open. My peer ID is:", id);
    });

    this.peer.on("disconnected", () => {
      console.log("Disconnected from PeerJS server");
      // Handle the disconnection event
      this.peer.reconnect();
    });

    this.peer.on("error", (error) => {
      console.error("PeerJS error:", error);
      // Handle the error event
    });

    this.peer.on("connection", (dataConnection) => {
      console.log("Incoming data connection:", dataConnection.peer);
      // Handle the incoming data connection
    });
  }
  on(event, callback) {
    if (this.peer) {
      this.peer.on(event, callback);
    }
  }

  call(remote_peer_id, localStream, data) {
    if (this.peer) {
      return this.peer.call(remote_peer_id, localStream, data);
    }
  }

  isConnected(peerId) {
    const connections = this.peer.connections[peerId];
    const isConnected = connections && connections.length > 0;
    return isConnected;
  }

  connectToPeer(peerId) {
    const dataConnection = this.peer.connect(peerId);
    console.log("Connecting to peer:", peerId);

    dataConnection.on("open", () => {
      console.log("Data connection established with peer:", peerId);
      // Handle the established data connection
    });

    dataConnection.on("data", (data) => {
      console.log("Received data from peer:", data);
      // Handle the received data
    });

    dataConnection.on("close", () => {
      console.log("Data connection closed with peer:", peerId);
      // Handle the closed data connection
    });

    dataConnection.on("error", (error) => {
      console.error("Data connection error with peer:", peerId, error);
      // Handle the data connection error
    });
  }

  answerCall(mediaConnection) {
    mediaConnection.answer(this.localStream);
    console.log("Answered incoming call from:", mediaConnection.peer);

    mediaConnection.on("stream", (remoteStream) => {
      console.log("Received remote stream from:", mediaConnection.peer);
      // Handle the remote stream
    });

    mediaConnection.on("close", () => {
      console.log("Media call with", mediaConnection.peer, "is closed");
      // Handle the closed media call
    });

    mediaConnection.on("error", (error) => {
      console.error("Media call error with", mediaConnection.peer, error);
      // Handle the media call error
    });
  }

  reconnect() {
    if (this.peer && !this.peer.disconnected) {
      console.log("Reconnecting to PeerJS server...");
      this.peer.reconnect();
    } else {
      console.log("PeerJS connection is already disconnected");
    }
  }

  close() {
    if (this.peer) {
      this.peer.destroy();
      this.peer = null;
    }
  }
}

const peerService = new PeerService();
export default peerService;
