import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useCall from '../../hooks/useCall';
import '../../styles.css';
import { IoPersonCircle, Io } from 'react-icons/io5';
import { Typography, Button, Badge } from 'antd';
import { BsArrowsFullscreen, BsChatLeftTextFill, BsPersonPlusFill, BsRecordBtn } from 'react-icons/bs';
import {
  MdOutlineMic,
  MdScreenShare,
  MdStopScreenShare,
  MdVideocam,
  MdVideocamOff,
  MdMicOff,
  MdFullscreen,
  MdPhoneDisabled,
  MdInfo,
  MdArrowDownward,
  MdArrowUpward,
  MdMinimize,
} from 'react-icons/md';

import { togglePopup, setPopupChildren } from '../../../../redux/globals/actions';
import MessageDialogue from '../MessageDialogue';
import useControlPanel from '../ControlPanel/hooks/useControlPanel';
import { useState } from 'react';
import socketService from '../../../../services/socket.service';
const { Text } = Typography;
const CallView = ({
  getDeviceStream,
  localStreamRef,
  toggleMic,
  toggleVideo,
  state,
  remoteStreamRef,
  shareScreen,
  stopSharingScreen,
  maximizeRemoteVideo,
  endCall,
}) => {
  const dispatch = useDispatch();
  const { localStream, onCall, caller, calling, remoteStream, callType, socket } = useSelector(
    (state) => state.globals,
  );
  const { onStartScreenRecording, handleStopRecording, recording, newCallMessages, setNewCallMessages } =
    useControlPanel();
  const [isMin, setIsMin] = useState(false);
  const { audioMode, videoMode, screenMode, fullScreen } = state;
  useEffect(() => {
    getDeviceStream(callType);

    socketService.on('end_call', ({ status, sender }) => {
      if (calling && status && sender === calling?.id) {
        endCall();
      }
    });
  }, []);
  useEffect(() => {
    if (remoteStream && remoteStreamRef && remoteStreamRef.current) {
      remoteStreamRef.current.srcObject = remoteStream;
    }
  }, [remoteStream]);

  const containerStyle = {
    marginBottom: isMin ? '-350px' : 0,
  };
  const drawerStyle = {
    paddingTop: isMin ? '100px' : 0,
  };

  return (
    <div
      className="call-view-container shadow-2xl backdrop-blur-xl bg-white/30 border-t-8 border-gray-200 overflow-visible"
      style={containerStyle}
    >
      {isMin && (
        <div className="flex flex-col justify-center items-center -mt-14">
          <Button
            onClick={() => setIsMin(!isMin)}
            className="m-1 flex flex-col items-center sm:justify-center rounded-full bg-primary text-white sm:w-10 sm:h-10"
            title={isMin ? 'Show call panel' : 'Hide call panel'}
          >
            {isMin ? <MdArrowUpward size={25} /> : <MdArrowDownward size={25} />}
          </Button>
        </div>
      )}

      <div className="call-view-inner flex flex-row pt-4 gap-2" style={drawerStyle}>
        <div className="local-stream-container border-gray-200 border">
          {callType == 'voice' ? (
            <div className="flex flex-col justify-center align-middle mt-8 ">
              <IoPersonCircle size={80} className="self-center" />
              <Text className="text-gray-400 text-md text-center" ellipsis={true} title="User">
                Voice Call
              </Text>
              <video
                muted={true}
                ref={localStreamRef}
                autoPlay
                playsInline
                style={{
                  display: 'none',
                }}
              />
            </div>
          ) : (
            <video
              muted={true}
              ref={localStreamRef}
              autoPlay
              playsInline
              style={{
                objectFit: 'cover',
              }}
            />
          )}
        </div>
        <div className="remote-stream-container text-gray-400 border-gray-200 border">
          {callType == 'voice' ? (
            <div className="flex flex-col justify-center align-middle mt-8 ">
              <IoPersonCircle size={80} className="self-center" />
              <Text className="text-gray-400 text-md text-center" ellipsis={true} title="User">
                Voice calling...
              </Text>
              <Text className="text-gray-400 text-sm text-center">{calling?.firstName + ' ' + calling?.lastName}</Text>
              <video
                ref={remoteStreamRef}
                autoPlay
                playsInline
                style={{
                  display: 'none',
                }}
              />
            </div>
          ) : remoteStream ? (
            <div className="relative">
              <Button
                onClick={maximizeRemoteVideo}
                title="Full screen"
                className="rounded-md absolute right-2 top-2 flex flex-col justify-center items-center w-8 h-8  border border-primary text-primary bg-transparent z-10"
              >
                <MdFullscreen size={20} />
              </Button>
              <video
                ref={remoteStreamRef}
                autoPlay
                playsInline
                style={{
                  objectFit: 'cover',
                }}
              />
            </div>
          ) : (
            <div className="flex flex-col justify-center align-middle">
              <IoPersonCircle size={80} className="self-center" />
              <Text
                className="text-gray-400 text-md text-center"
                ellipsis={true}
                title={calling?.firstName + ' ' + calling?.lastName}
              >
                Video calling...
              </Text>
              <Text className="text-gray-400 text-sm text-center">{calling?.firstName + ' ' + calling?.lastName}</Text>
            </div>
          )}
        </div>
      </div>

      <div className="pb-5 sm:w-[95%] md:w-[90%]">
        <>
          <div className="flex sm:justify-start justify-evenly md:justify-start items-center mb-3 px-4 py-2 h-full w-full bg-white border border-gray-200  rounded-full shadow-2xl overflow-auto">
            {/* <div className="flex flex-col justify-center items-center">
              <Button
                className="m-1 flex flex-col items-center sm:justify-center rounded-full bg-primary text-white sm:w-10 sm:h-10"
                title="Meeting information"
              >
                <MdInfo size={25} />
              </Button>
              <span className="text-[10px] text-gray-500 text-center truncate w-16">Meeting information</span>
            </div> */}

            <div className="flex flex-col justify-center items-center">
              <Button
                className={`m-1 flex flex-col items-center sm:justify-center rounded-full ${
                  audioMode ? 'bg-yellow-700' : 'bg-primary'
                }  text-white sm:w-10 sm:h-10`}
                title={audioMode ? 'Mute' : 'Unmute'}
                onClick={toggleMic}
              >
                {audioMode ? <MdOutlineMic size={25} /> : <MdMicOff />}
              </Button>
              <span className="text-[10px] text-gray-500 text-center truncate w-16">
                {audioMode ? 'Mute' : 'Unmute'}
              </span>
            </div>
            {callType == 'video' && (
              <div className="flex flex-col justify-center items-center">
                <Button
                  className={`m-1 flex flex-col items-center sm:justify-center rounded-full ${
                    videoMode ? 'bg-yellow-700' : 'bg-primary'
                  }  text-white sm:w-10 sm:h-10`}
                  title={videoMode ? 'Turn off Camera' : 'Turn on Camera'}
                  onClick={toggleVideo}
                >
                  {videoMode ? <MdVideocam size={20} /> : <MdVideocamOff size={20} />}
                </Button>
                <span className="text-[10px] text-gray-500 text-center truncate w-16">
                  {audioMode ? 'Turn off Camera' : 'Turn on Camera'}
                </span>
              </div>
            )}

            {callType == 'video' ? (
              screenMode ? (
                <div className="flex flex-col justify-center items-center">
                  <Button
                    className="m-1 flex flex-col items-center sm:justify-center rounded-full bg-yellow-700 text-white sm:w-10 sm:h-10"
                    onClick={stopSharingScreen}
                    title="Stop Sharing"
                  >
                    <MdStopScreenShare size={25} />
                  </Button>
                  <span className="text-[10px] text-gray-500 text-center truncate w-16">Stop Sharing</span>
                </div>
              ) : (
                <div className="flex flex-col justify-center items-center">
                  <Button
                    className="m-1 flex flex-col items-center sm:justify-center rounded-full bg-primary text-white sm:w-10 sm:h-10"
                    onClick={shareScreen}
                    title="Share screen"
                  >
                    <MdScreenShare size={25} />
                  </Button>
                  <span className="text-[10px] text-gray-500 text-center truncate w-16">Share screen</span>
                </div>
              )
            ) : null}

            {!recording ? (
              <div className="flex flex-col justify-center items-center ">
                <Button
                  onClick={onStartScreenRecording}
                  className="m-1 flex flex-col items-center sm:justify-center rounded-full bg-primary text-white sm:w-10 sm:h-10"
                  title="Record Screen"
                >
                  <BsRecordBtn size={20} />
                </Button>
                <span className="text-[10px] text-gray-500 text-center truncate w-16">Record Screen</span>
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center">
                <Button
                  onClick={handleStopRecording}
                  className="m-1 flex flex-col items-center sm:justify-center rounded-full bg-yellow-500 text-white sm:w-10 sm:h-10 "
                  title="Stop Recording"
                >
                  <BsRecordBtn className="recording-btn" size={25} />
                </Button>
                <span className="text-[10px] text-gray-500 text-center truncate w-16">Stop Recording</span>
              </div>
            )}

            {/* <div className="flex flex-col justify-center items-center relative">
              <Button
                className="m-1 flex items-center sm:justify-center rounded-full bg-primary text-white sm:w-10 sm:h-10"
                title="Participants"
              >
                <span className="flex participants-count-wrapper items-center h-[14px] w-[14px] relative mr-2">
                  <span className="absolute rounded-full h-[14px] w-[14px] text-[10px] text-dark bg-red-200">3</span>
                </span>
                <IoPersonCircle size={25} />
              </Button>
              <span className="text-[10px] text-gray-500 text-center truncate w-16">Participants</span>
            </div> */}

            <div className="flex flex-col justify-center items-center relative">
              <Button
                className="m-1 flex items-center sm:justify-center rounded-full bg-primary text-white sm:w-10 sm:h-10"
                title="Chats"
                onClick={() => {
                  dispatch(togglePopup(true));
                  dispatch(setPopupChildren(<MessageDialogue setNewCallMessages={setNewCallMessages} />));
                  setNewCallMessages(0);
                }}
              >
                <Badge count={newCallMessages} overflowCount={10}>
                  <BsChatLeftTextFill size={25} className="text-white " />
                </Badge>
              </Button>

              <span className="text-[10px] text-gray-500 text-center truncate w-16">Chats</span>
            </div>

            <div className="flex flex-col justify-center items-center">
              <Button
                onClick={() => setIsMin(!isMin)}
                className="m-1 flex flex-col items-center sm:justify-center rounded-full bg-primary text-white sm:w-10 sm:h-10"
                title="Hide Video panel"
              >
                <MdArrowDownward size={25} />
              </Button>
              <span className="text-[10px] text-gray-500 text-center truncate w-16">Hide Video panel</span>
            </div>

            {/* <Button
                onClick={() => setIsMin(!isMin)}
                title="Minimize"
                className="rounded-md absolute right-12 top-2 flex flex-col justify-center items-center w-8 h-8  border border-primary text-primary bg-transparent z-10"
              >
                <MdMinimize size={20} />
              </Button> */}

            <div className="flex flex-col justify-center items-center">
              <Button
                onClick={endCall}
                className="m-1 flex flex-col items-center sm:justify-center rounded-full bg-red-500 text-white sm:w-10 sm:h-10"
                title="End call"
              >
                <MdPhoneDisabled color="white" size={20} />
              </Button>
              <span className="text-[10px] text-gray-500 text-center truncate w-16">End call</span>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default CallView;
