import React, { useEffect } from 'react';
import ImageSlider from './ImageSlider';
// import Logo from '../../../static/img/xo-bridge-white.png';
import Logo from '../../../static/img/dicomx-logo-white-new.png';
import feature1 from '../../../static/img/filters_format.webp';
import feature2 from '../../../static/img/filters_format2.webp';
import feature3 from '../../../static/img/filters_format3.webp';
import feature4 from '../../../static/img/filters_format4.svg';
import feature5 from '../../../static/img/filters_format5.svg';
import feature6 from '../../../static/img/filters_format6.svg';
import radiologist from '../../../static/img/filters_format7.webp';
import hospitals from '../../../static/img/filters_format5.webp';
import accessible from '../../../static/img/xo-accessible.png';
import why from '../../../static/img/xo-why.png';
import contact from '../../../static/img/xo-contact.png';
import { useLocation, useNavigate } from 'react-router-dom';
import videoSource from '../../../assets/img/Block-1-New-2.mp4';
import { Button, Col, Form, Input, Row, message, Collapse } from 'antd';
import { Link } from 'react-scroll';
import { RiMenu5Fill } from 'react-icons/ri';
import { IoCloseCircleOutline } from 'react-icons/io5';
import './style.css';
import {
  Uil500px,
  UilAirplay,
  UilArrowGrowth,
  UilAt,
  UilBagAlt,
  UilBookAlt,
  UilBookOpen,
  UilBookReader,
  UilCalendarAlt,
  UilChartBar,
  UilChat,
  UilCheckSquare,
  UilCircle,
  UilClipboardAlt,
  UilClock,
  UilCompactDisc,
  UilCreateDashboard,
  UilDatabase,
  UilDocumentLayoutLeft,
  UilEdit,
  UilEnvelope,
  UilExchange,
  UilExclamationOctagon,
  UilMap,
} from '@iconscout/react-unicons';

import { PhoneOutlined } from '@ant-design/icons';
const { TextArea } = Input;

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const imgContainer = {
  height: 'calc(100vh - 80px)',
};

const text = `
A dog is a type of domesticated animal.
Known for its loyalty and faithfulness,
it can be found as a welcome guest in many households across the world.
`;
const items = [
  {
    key: '1',
    label: 'This is panel header 1',
    children: <p>{text}</p>,
  },
  {
    key: '2',
    label: 'This is panel header 2',
    children: <p>{text}</p>,
  },
  {
    key: '3',
    label: 'This is panel header 3',
    children: <p>{text}</p>,
  },
];

function HomePage() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const onChange = (key) => {
    console.log(key);
  };

  function toggleMenu() {
    var menuToggle = document.querySelector('.nav-open');
    var navigation = document.querySelector('.nav-content');
    menuToggle.classList.toggle('active');
    navigation.classList.toggle('active');
  }

  function onClose() {
    var menuToggle = document.querySelector('.nav-open');
    var navigation = document.querySelector('.nav-content');
    menuToggle.classList.remove('active');
    navigation.classList.remove('active');
  }

  return (
    <div className="bg-secondary">
      {/* Navigation bar section */}
      <div class="flex justify-center fixed top-0 w-full bg-primary h-[70px] shadow-md z-50 nav-top">
        <div className="px-8 sm:px-2 md:px-5 lg:px-3 flex justify-between md:justify-between xl:justify-between items-center text-[#FFF] w-full nav-box">
          <div className="pr-12  pl-4">
            <div onClick={() => navigate('/')}>
              <img className="w-full max-w-[150px] min-w-[100px] xs:max-w-[100px]" src={Logo} alt="" />
            </div>
          </div>

          <div className="flex justify-center items-center gap-[40px] text-[1.0rem] font-light sm:text-sm nav-content lg:shadow-2xl">
            <Link className="text-white" to="home" smooth={true} duration={500} spy={true} onClick={() => onClose()}>
              <div
                // onClick={() => {
                //   navigate('/');
                // }}
                className="menu-item transition-transform hover:scale-105 hover:text-white cursor-pointer"
              >
                Home
              </div>
            </Link>

            <Link className="text-white" to="about" smooth={true} duration={500} spy={true} onClick={() => onClose()}>
              <div className="menu-item transition-transform hover:scale-105 hover:text-white cursor-pointer">
                About
              </div>
            </Link>

            <Link className="text-white" to="how" smooth={true} duration={500} spy={true} onClick={() => onClose()}>
              <div className="menu-item transition-transform hover:scale-105 hover:text-white cursor-pointer">
                How it Works
              </div>
            </Link>

            <Link
              className="text-white"
              to="features"
              smooth={true}
              duration={500}
              spy={true}
              onClick={() => onClose()}
            >
              <div className="menu-item transition-transform hover:scale-105 hover:text-white  cursor-pointer">
                Features
              </div>
            </Link>

            <Link className="text-white" to="faqs" smooth={true} duration={500} spy={true} onClick={() => onClose()}>
              <div className="menu-item transition-transform hover:scale-105 hover:text-white cursor-pointer">FAQs</div>
            </Link>

            <Link
              className="text-white"
              to="contact-us"
              smooth={true}
              duration={500}
              spy={true}
              onClick={() => onClose()}
            >
              <div className="menu-item transition-transform hover:scale-105 hover:text-white cursor-pointer">
                Contact
              </div>
            </Link>

            <div className="menu-item transition-transform hover:scale-105">
              <Button
                size="large"
                onClick={() => navigate('/app/auth')}
                className="bg-white87 hover:text-gray-800 hover:bg-white87 border-solid border-1 border-primary text-primary text-[14px] font-semibold leading-[22px] inline-flex items-center justify-center rounded-full px-5 py-[5px]"
              >
                Sign In
              </Button>
            </div>
            <div className="nav-close" onClick={() => toggleMenu()}>
              <IoCloseCircleOutline />
            </div>
          </div>

          <div className="nav-open" onClick={() => toggleMenu()}>
            <RiMenu5Fill />
          </div>
        </div>
      </div>

      <div id="home" className="m-0 p-0">
        {/* Introduction text section */}
        <div
          style={{ backgroundImage: `url("${require('../../../static/img/world-map.png')}")` }}
          className="flex pt-44 sm:pt-28 pb-0 px-[80px] md:px-0 lg:px-0 xl:px-5 justify-center items-center flex-col w-full bg-fixed bg-top bg-no-repeat"
        >
          <div className="flex flex-col justify-center items-center gap-[30px] sm:gap-[15px] md:gap-[20px] p-2 pb-10 sm:pb-5 md:pb-5 xl:pb-6">
            <div className="px-16 sm:px-3 md:px-3">
              <p className="font-semibold text-5xl sm:text-4xl text-light text-center drop-shadow-lg tracking-wide leading-[60px]">
                DICOM-X,
                <br />
                Your Ultimate AI-Assisted&nbsp;
                <br className="sm:hidden" />
                Collaborative DICOM Viewer&nbsp;
              </p>
            </div>
            <p className="font-normal text-2xl sm:text-xl text-light text-center px-60 sm:px-3 lg:px-32 xl:px-40 drop-shadow-lg">
              Say hello to a whole new era of real-time medical image analysis, backed by powerful AI assistance and a
              host of innovative features.
            </p>
            <Button
              size="large"
              onClick={() => navigate('/app/auth')}
              className="bg-primary hover:bg-white hover:text-primary border-solid border-1 border-primary text-white dark:text-white87 text-[16px] font-semibold leading-[22px] inline-flex items-center justify-center rounded-full px-14 py-[5px] drop-shadow-lg"
            >
              Get Started
            </Button>
          </div>
        </div>

        {/* How it works video */}
        <div
          id="how"
          className="flex justify-center align-middle mb-10 sm:mb-10 md:mb-12 py-20 sm:py-10 md:py-10 xl:py-10 px-[200px] sm:px-5 md:px-5 xl:px-[100px]"
        >
          {/* <p className="uppercase font-semibold text-sm text-gray-300 text-left pb-5 px-2">How it works</p> */}
          <div className="h-[700px] max-w-5xl sm:h-auto md:h-auto xl:h-[550px] rounded-3xl overflow-hidden drop-shadow-2xl shadow-2xl border border-primary">
            {/* <ImageSlider /> */}
            <div className="bg-[#EBF6F8]">
              <video
                loop
                muted
                autoPlay
                className="w-full"
                style={{
                  objectFit: 'cover',
                }}
              >
                <source src={videoSource} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>

        {/* About us text */}
        <section
          id="about"
          className="flex flex-col justify-center mb-10 sm:mb-5 md:mb-5 py-20 sm:py-10 md:py-14 sm:px-0 md:px-5 lg:px-[20px] xl:px-[50px] bg-primary w-full"
        >
          <div className="flex flex-col justify-center align-middle gap-y-2">
            <p className="uppercase font-semibold text-sm text-gray-300 text-center pb-5">About DICOM-X</p>
            <div className="flex md:flex-col justify-between align-middle px-16 sm:px-0 md:px-3 lg:px-0 xl:px-0">
              <div className="px-3 pt-0 lg:px-[20px] w-1/2 sm:w-full md:w-full">
                <h1 className="font-semibold text-3xl sm:text-2xl lg:text-[28px] text-gray-300 text-left">
                  Let's make healthcare accessible to all. Together!
                </h1>
                <p className="font-normal text-gray-300 text-2xl sm:text-xl md:text-xl lg:text-[18px] text-left">
                  With a relentless commitment to innovation, we have crafted a web application that goes beyond
                  conventional boundaries. DICOM-X is not just a viewer; it's a comprehensive platform that seamlessly
                  integrates AI intelligence, real-time communication, and advanced features to enhance the accuracy,
                  efficiency, and collaborative nature of medical image analysis.
                  <br />
                  <br />
                  Our platform is designed to bridge the gap between medical professionals, enabling them to connect,
                  communicate, and collaborate effortlessly. We understand the importance of timely insights in the
                  medical field, and that's why DICOM-X provides real-time AI assistance, ensuring informed decisions
                  can be made swiftly.
                </p>
              </div>
              <div className="p-3 w-[500px] sm:w-full md:w-full lg:w-[450px]">
                <img className="w-full drop-shadow-md" src={accessible} alt="" />
              </div>
            </div>
          </div>
        </section>

        {/* Features section */}
        <section
          id="features"
          style={{ backgroundImage: `url("${require('../../../static/img/xolani-dicomx-transparent.png')}")` }}
          className="flex flex-col justify-center mb-10 sm:mb-10 md:mb-12 py-16 sm:py-8 md:py-10 sm:px-0 md:px-5 lg:px-[20px] xl:px-[50px] bg-fixed bg-center bg-no-repeat"
        >
          <div className="flex flex-col justify-between align-middle px-20 sm:px-5 md:px-8 xl:px-0 sm:w-full md:w-full gap-y-2">
            <p className="uppercase font-semibold text-sm text-gray-300 text-center pb-5 drop-shadow-lg">
              Our Features
            </p>
            <h1 className="font-semibold text-3xl sm:text-2xl lg:text-[28px] text-gray-300 text-left drop-shadow-lg">
              Empowering radiology services with
              <br />
              advanced features
            </h1>

            <p className="font-normal text-gray-300 max-w-4xl text-2xl sm:text-xl md:text-xl lg:text-[18px] text-left drop-shadow-lg">
              Whether you're an individual professional or part of a medical institution, DICOM-X caters to your
              specific needs.
            </p>
          </div>

          <div className="py-5 px-20 sm:px-5 md:px-8 xl:px-0 lg:px-[20px] sm:w-full md:w-full gap-y-2">
            <Row gutter={25}>
              <Col xs={24} sm={24} md={12} xl={8} xxl={8}>
                <div className="rounded-3xl overflow-hidden shadow-md bg-white87 p-6 mb-8 h-[300px]">
                  <div className="rounded-2xl px-3 pb-2">
                    <div className="flex justify-end">
                      <img className="w-full max-w-[50px] xs:max-w-[50px]" src={feature1} alt="" />
                    </div>
                    <div className="flex flex-col gap-y-2">
                      <h1 className="font-semibold text-2xl text-secondary text-left pt-6">
                        Unparalleled Collaboration
                      </h1>
                    </div>
                    <p className="font-normal text-secondary text-2xl sm:text-xl md:text-xl lg:text-[18px] text-left">
                      Connect and collaborate seamlessly with our built-in chat, voice, and video features.
                    </p>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} xl={8} xxl={8}>
                <div className="rounded-3xl overflow-hidden shadow-md bg-white87 p-6 mb-8 h-[300px]">
                  <div className="rounded-2xl px-3 pb-2">
                    <div className="flex justify-end">
                      <img className="w-full max-w-[50px] xs:max-w-[50px]" src={feature2} alt="" />
                    </div>
                    <div className="flex flex-col gap-y-2">
                      <h1 className="font-semibold text-2xl text-secondary text-left pt-6">Real-Time Insights</h1>
                    </div>
                    <p className="font-normal text-secondary text-2xl sm:text-xl md:text-xl lg:text-[18px] text-left">
                      Our AI assistance empowers you with real-time insights while analyzing medical images.
                    </p>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} xl={8} xxl={8}>
                <div className="rounded-3xl overflow-hidden shadow-md bg-white87 p-6 mb-8 h-[300px]">
                  <div className="rounded-2xl px-3 pb-2">
                    <div className="flex justify-end">
                      <img className="w-full max-w-[50px] xs:max-w-[50px]" src={feature3} alt="" />
                    </div>
                    <div className="flex flex-col gap-y-2">
                      <h1 className="font-semibold text-2xl text-secondary text-left pt-6">Annotate and Share</h1>
                    </div>
                    <p className="font-normal text-secondary text-2xl sm:text-xl md:text-xl lg:text-[18px] text-left">
                      Take annotated screenshots, or even send screen recordings to enhance discussions.
                    </p>
                  </div>
                </div>
              </Col>

              <Col xs={24} sm={24} md={12} xl={8} xxl={8}>
                <div className="rounded-3xl overflow-hidden shadow-md bg-white87 p-6 mb-8 h-[300px]">
                  <div className="rounded-2xl px-3 pb-2">
                    <div className="flex justify-end">
                      <img className="w-full max-w-[50px] xs:max-w-[50px]" src={feature4} alt="" />
                    </div>
                    <div className="flex flex-col gap-y-2">
                      <h1 className="font-semibold text-2xl text-secondary text-left pt-6">Remote Desktop Access</h1>
                    </div>
                    <p className="font-normal text-secondary text-2xl sm:text-xl md:text-xl lg:text-[18px] text-left">
                      Enabling and access remote consultation and diagnosis, all within the DICOM-X ecosystem.
                    </p>
                  </div>
                </div>
              </Col>

              <Col xs={24} sm={24} md={12} xl={8} xxl={8}>
                <div className="rounded-3xl overflow-hidden shadow-md bg-white87 p-6 mb-8 h-[300px]">
                  <div className="rounded-2xl px-3 pb-2">
                    <div className="flex justify-end">
                      <img className="w-full max-w-[50px] xs:max-w-[50px]" src={feature5} alt="" />
                    </div>
                    <div className="flex flex-col gap-y-2">
                      <h1 className="font-semibold text-2xl text-secondary text-left pt-6">Convenient Scheduling</h1>
                    </div>
                    <p className="font-normal text-secondary text-2xl sm:text-xl md:text-xl lg:text-[18px] text-left">
                      Plan meetings and consultations seamlessly with built-in meeting scheduling.
                    </p>
                  </div>
                </div>
              </Col>

              <Col xs={24} sm={24} md={12} xl={8} xxl={8}>
                <div className="rounded-3xl overflow-hidden shadow-md bg-white87 p-6 mb-8 h-[300px]">
                  <div className="rounded-2xl px-3 pb-2">
                    <div className="flex justify-end">
                      <img className="w-full max-w-[50px] xs:max-w-[50px]" src={feature6} alt="" />
                    </div>
                    <div className="flex flex-col gap-y-2">
                      <h1 className="font-semibold text-2xl text-secondary text-left pt-6">Image Sharing Made Easy</h1>
                    </div>
                    <p className="font-normal text-secondary text-2xl sm:text-xl md:text-xl lg:text-[18px] text-left">
                      Send DICOM files to colleagues instantly, facilitating quick consultations and second opinions.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </section>

        {/* Our Services */}
        {/* <section
          id="services"
          className="flex flex-col justify-center mb-0 sm:mb-0 md:mb-0 py-20 sm:py-10 md:py-0 xl:py-0 px-20 sm:px-5 md:px-5 bg-light w-full"
        >
          <div className="flex flex-col justify-center align-middle gap-y-2 mt-10 sm:mt-10 pb-10 sm:pb-10">
            <p className="uppercase font-semibold text-sm text-secondary text-center pb-5">Our services</p>
            <Row gutter={25} className="flex justify-center align-middle">
              <Col xs={24} sm={24} md={24} xl={11} xxl={11}>
                <div className="mt-5">
                  <div className="rounded-3xl overflow-hidden shadow-md p-10 sm:p-6 mb-8 bg-[#1A2835]">
                    <div className="px-3 pb-2">
                      <h1 className="font-semibold text-2xl text-white60 text-left">For Hospitals/Diagnosis center</h1>
                      <div className="flex justify-end">
                        <img className="w-full max-w-[100px] xs:max-w-[100px]" src={hospitals} alt="" />
                      </div>
                      <div className="flex flex-col">
                        <h1 className="font-semibold text-2xl sm:text-xl text-white60 text-left pt-6">
                          Access Highly Skilled Radiologists
                        </h1>
                      </div>
                      <p className="font-normal text-white60 text-2xl sm:text-xl md:text-xl lg:text-[18px] text-left">
                        We've developed a solution for you that connects you with a team of highly skilled radiologists.
                      </p>

                      <Button
                        size="large"
                        onClick={() => navigate('/')}
                        className="bg-white hover:text-gray-800 hover:bg-white text-primary dark:text-white87 text-[14px] font-semibold leading-[22px] inline-flex items-center justify-center rounded-full px-5 py-[5px] transition-transform hover:scale-105"
                      >
                        Learn more
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} xl={11} xxl={11}>
                <div className="mt-5">
                  <div className="rounded-3xl overflow-hidden shadow-md p-10 sm:p-6 mb-8 bg-[#1A2835]">
                    <div className="px-3 pb-2">
                      <h1 className="font-semibold text-2xl text-white60 text-left">For Radiologists</h1>
                      <div className="flex justify-end">
                        <img className="w-full max-w-[100px] xs:max-w-[100px]" src={radiologist} alt="" />
                      </div>
                      <div className="flex flex-col">
                        <h1 className="font-semibold text-2xl sm:text-xl text-white60 text-left pt-6">
                          Work Remotely and Earn More
                        </h1>
                      </div>
                      <p className="font-normal text-white60 text-2xl sm:text-xl md:text-xl lg:text-[18px] text-left sm:p-0">
                        Are you a radiologist seeking a better work-life balance, increased earning potential, and...
                      </p>

                      <Button
                        size="large"
                        onClick={() => navigate('/')}
                        className="bg-white hover:text-gray-800 hover:bg-white text-primary dark:text-white87 text-[14px] font-semibold leading-[22px] inline-flex items-center justify-center rounded-full px-5 py-[5px] transition-transform hover:scale-105"
                      >
                        Learn more
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </section> */}

        {/* Why DICOM-X */}
        <section
          id="why"
          className="flex flex-col justify-center mb-10 sm:mb-5 md:mb-5 py-20 sm:py-10 md:py-14 sm:px-0 md:px-5 lg:px-[20px] xl:px-[50px] w-full"
        >
          <div className="flex flex-col justify-center align-middle gap-y-2">
            <p className="uppercase font-semibold text-sm text-gray-300 text-center pb-5">Why DICOM-X</p>
            <div className="flex md:flex-col justify-between align-middle px-16 sm:px-0 md:px-3 lg:px-0 xl:px-0">
              <div className="p-3 w-[500px] sm:w-full md:w-full lg:w-[450px]">
                <img className="w-full drop-shadow-md" src={why} alt="" />
              </div>
              <div className="px-3 pt-0 lg:px-[20px] w-1/2 sm:w-full md:w-full">
                <h1 className="font-semibold text-3xl sm:text-2xl lg:text-[28px] text-gray-300 text-left">
                  Revolutionize the way you analyze medical images
                </h1>
                <p className="font-normal text-gray-300 text-2xl sm:text-xl md:text-xl lg:text-[18px] text-left">
                  DICOM-X is at the forefront of revolutionizing medical image analysis and collaboration. Our mission
                  is to empower radiologists and doctors with a cutting-edge AI-assisted DICOM Viewer that transforms
                  the way medical images are analyzed, shared, and discussed.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* FAQs */}
        <section
          id="faqs"
          className="flex flex-col justify-center mb-10 sm:mb-10 md:mb-12 py-10 sm:py-10 md:py-0 xl:py-0 px-40 sm:px-5 md:px-5 xl:px-[100px] bg-primary"
        >
          <span className="uppercase font-semibold text-sm text-gray-300 text-center py-10">Faqs</span>

          <div className="flex flex-col justify-center align-middle gap-y-2 pt-5">
            <Accordion allowZeroExpanded className="border-none">
              <AccordionItem className="border-l-1 border-primary rounded-3xl overflow-hidden shadow-md bg-white87 p-6 mb-8">
                <AccordionItemHeading>
                  <AccordionItemButton className="bg-transparent text-secondary font-semibold">
                    What is DICOM-X all about?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className="bg-transparent text-secondary px-10 py-4">
                  DICOM-X is a revolutionary AI-assisted collaborative DICOM Viewer designed to empower radiologists and
                  medical professionals with an unparalleled experience in analyzing medical images in real time. It is
                  a dynamic web application that seamlessly integrates advanced artificial intelligence and innovative
                  communication tools to enhance the accuracy of medical diagnoses and streamline collaboration among
                  healthcare experts.
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </section>

        {/* Contact us */}
        <section
          id="contact-us"
          // style={{ backgroundImage: `url("${require('../../../static/img/world-map.png')}")` }}
          className="flex flex-col justify-center mb-0 py-20 sm:py-10 md:py-14 sm:px-0 md:px-5 lg:px-[20px] xl:px-[50px] w-ful"
        >
          <div className="flex flex-col justify-center align-middle gap-y-2">
            <div className="flex flex-col justify-between align-middle px-20 sm:px-5 md:px-8 xl:px-0 sm:w-full md:w-full gap-y-2">
              <p className="uppercase font-semibold text-sm text-gray-300 text-center pb-5">Contact us</p>
              <h1 className="font-semibold text-3xl sm:text-2xl lg:text-[28px] text-gray-300 text-left">
                Reach out to us now!
              </h1>
              <p className="font-normal text-gray-300 max-w-4xl text-2xl sm:text-xl md:text-xl lg:text-[18px] text-left">
                We would love to hear from you! Contact us using the contact details below or fill out the contact form.
                Our dedicated team is here to answer your questions and help you with any help you need.
              </p>
            </div>
            <div className="flex md:flex-col-reverse justify-between align-middle px-20 sm:px-0 md:px-3 lg:px-0 xl:px-0 rounded-2xl drop-shadow-lg">
              <div className="p-3 w-[500px] sm:w-full md:w-full lg:w-[450px]">
                <img className="drop-shadow-md md:pt-8 w-[200px]" src={contact} alt="contact-us" />
                <div className="my-10">
                  <span className="font-normal text-sm text-gray-300 flex justify-start align-middle text-left gap-2">
                    <UilMap style={{ fontSize: '16px', color: '#0497A6' }} />
                    <p> No. 5, Kwaji Close, Maitama - Abuja, Nigeria.</p>
                  </span>
                  <span className="font-normal text-sm text-gray-300 flex justify-start align-middle text-left gap-2">
                    <PhoneOutlined style={{ fontSize: '25px', color: '#0497A6' }} />
                    <p> +234 904 000 4400</p>
                  </span>
                  <span className="font-normal text-sm text-gray-300 flex justify-start align-middle text-left gap-2">
                    <UilEnvelope style={{ fontSize: '16px', color: '#0497A6' }} />
                    <p>hello@xolanihealth.com</p>
                  </span>
                </div>
              </div>

              <div className="p-8 lg:px-[20px] w-1/2 sm:w-full md:w-full rounded-3xl bg-light">
                <Form name="register" layout="vertical">
                  <div className="flex flex-row w-full gap-2 justify-between items-center">
                    <Form.Item
                      label="Name"
                      name="name"
                      className="[&>div>div>label]:text-sm [&>div>div>label]:text-dark dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium w-1/2"
                      rules={[{ required: true, message: 'Please input your name!' }]}
                    >
                      <Input placeholder="Name" />
                    </Form.Item>
                    <Form.Item
                      label="Email Address"
                      name="email"
                      className="[&>div>div>label]:text-sm [&>div>div>label]:text-dark dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium w-1/2"
                      rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}
                    >
                      <Input placeholder="Email Address" />
                    </Form.Item>
                  </div>
                  <div className="flex flex-col w-full">
                    <Form.Item
                      label="Subject"
                      name="subject"
                      className="[&>div>div>label]:text-sm [&>div>div>label]:text-dark dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium w-full"
                      rules={[{ required: true, message: 'Please input your subject' }]}
                    >
                      <Input placeholder="Subject" />
                    </Form.Item>
                    <Form.Item name="message" label="Message">
                      <TextArea
                        value={message}
                        onChange={(e) => dispatch({ message: e.target.value })}
                        placeholder="Message"
                      />
                    </Form.Item>
                  </div>
                  <Button
                    size="large"
                    // onClick={() => navigate('/app/auth')}
                    className="bg-primary hover:bg-white hover:text-primary border-solid border-1 border-primary text-white dark:text-white87 text-[16px] font-semibold leading-[22px] inline-flex items-center justify-center rounded-full px-14 py-[5px]"
                  >
                    Submit
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </section>

        {/* Footer */}
        <section
          id="footer"
          className="flex flex-col justify-center mb-0 py-10 sm:py-10 md:py-0 xl:py-0 px-20 sm:px-5 md:px-5 bg-dark w-full"
        >
          <div className="flex flex-col justify-center align-middle gap-y-2 mt-20 sm:mt-10 md:mt-10 pb-20 sm:pb-10">
            <span className="uppercase font-semibold text-sm text-white60 text-left">DICOM-X</span>
            <div className="mt-5">
              <h1 className="font-semibold text-2xl text-light text-left pt-6 sm:pt-3">Quick Navigation</h1>
              <Button
                className="px-3 h-12 bg-transparent text-white60 hover:bg-transparent hover:text-primary border-white60 hover:border-primary py-0 m-2 text-sm font-medium"
                size="small"
                onClick={() => navigate('/app/auth')}
              >
                Sign in
              </Button>
              <Link to="about" smooth={true} duration={500}>
                <Button
                  className="px-3 h-12 bg-transparent text-white60 hover:bg-transparent hover:text-primary border-white60 hover:border-primary py-0 m-2 text-sm font-medium"
                  size="small"
                >
                  About
                </Button>
              </Link>
              {/* <Button
                className="px-3 h-12 bg-transparent text-white60 hover:bg-transparent hover:text-primary border-white60 hover:border-primary py-0 m-2 text-sm font-medium"
                size="small"
                onClick={() => window.open('/privacy', '_blank')}
              >
                Privacy policy
              </Button>
              <Button
                className="px-3 h-12 bg-transparent text-white60 hover:bg-transparent hover:text-primary border-white60 hover:border-primary py-0 m-2 text-sm font-medium"
                size="small"
                onClick={() => window.open('/terms-conditions', '_blank')}
              >
                Terms & Conditions
              </Button> */}
            </div>

            <div className="mt-5">
              <h1 className="font-semibold text-2xl text-light text-left pt-6 sm:pt-3">Contact Us </h1>
              <p className="font-semibold text-sm text-white60 text-left">
                Address: No. 5, Kwaji Close, Maitama - Abuja, Nigeria.
              </p>
              <p className="font-semibold text-sm text-white60 text-left">
                <a className="text-white60" href="tel:+2349040004400" target="_blank">
                  Phone: +234 904 000 4400
                </a>
              </p>
              <p className="font-semibold text-sm text-white60 text-left">
                <a className="text-white60" href="mailto:hello@xolanihealth.com" target="_blank">
                  Email: hello@xolanihealth.com
                </a>
              </p>
            </div>
          </div>
        </section>
        <div class="copyright w-full p-2 bg-dark text-white text-center font-bold">
          Copyright 2021 - {new Date().getFullYear()},
          <a className="text-white" href="https://xolanihealth.com" target="_blank">
            {' '}
            Xolani Health Inc.{' '}
          </a>
          All rights reserved.
        </div>
      </div>
    </div>
  );
}

export default HomePage;
