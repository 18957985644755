import {
  UilBagAlt,
  UilSetting,
  UilUsersAlt,
  UilWindowSection,
  UilCreateDashboard,
  UilEdit,
} from '@iconscout/react-unicons';
import UilSignout from '@iconscout/react-unicons/icons/uil-signout';
import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';
import { changeDirectionMode, changeLayoutMode, changeMenuMode } from '../redux/themeLayout/actionCreator';
import { logOut } from '../redux/authentication/actionCreator';
import { removeUserSession } from '../utility/helpers';

function MenuItems({ toggleCollapsed }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const myData = useSelector((state) => state.user.myData);

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const path = '/app';

  const pathName = window.location.pathname;
  const pathArray = pathName.split('/');
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath?.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit?.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const changeLayout = (mode) => {
    dispatch(changeLayoutMode(mode));
  };
  const changeNavbar = (topMode) => {
    const html = document.querySelector('html');
    if (topMode) {
      html.classList.add('hexadash-topmenu');
    } else {
      html.classList.remove('hexadash-topmenu');
    }
    dispatch(changeMenuMode(topMode));
  };
  const changeLayoutDirection = (rtlMode) => {
    if (rtlMode) {
      const html = document.querySelector('html');
      html.setAttribute('dir', 'rtl');
    } else {
      const html = document.querySelector('html');
      html.setAttribute('dir', 'ltr');
    }
    dispatch(changeDirectionMode(rtlMode));
  };

  const darkmodeActivated = () => {
    document.body.classList.add('dark');
  };

  const darkmodeDiactivated = () => {
    document.body.classList.remove('dark');
  };

  const handleLogout = (values) => {
    removeUserSession();
    dispatch(logOut(() => history('/app/auth')));
  };

  const items = [
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}`}>
        {t('Dashboard')}
      </NavLink>,
      'demo-1',
      !topMenu && (
        <NavLink className="menuItem-iocn">
          <UilCreateDashboard />
        </NavLink>
      ),
    ),

    getItem(t('Studies'), 'Studies', !topMenu && <UilWindowSection />, [
      getItem(
        <NavLink onClick={() => toggleCollapsed} to={`/app/studies`}>
          {t('View Studies')}
        </NavLink>,
        'View Studies',
        null,
      ),
      getItem(
        <NavLink onClick={() => toggleCollapsed} to={`/app/radiologists`}>
          {t('Upload Studies')}
        </NavLink>,
        'Upload Studies',
        null,
      ),
    ]),

    // getItem(
    //   <NavLink onClick={() => toggleCollapsed} to={`/app/regulars`}>
    //     {t('Regulars')}
    //   </NavLink>,
    //   'regulars',
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn">
    //       <UilUsersAlt />
    //     </NavLink>
    //   ),
    // ),

    // getItem(
    //   <NavLink onClick={() => toggleCollapsed} to={`/app/payments`}>
    //     {t('Payments')}
    //   </NavLink>,
    //   'payments',
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn">
    //       <UilBagAlt />
    //     </NavLink>
    //   ),
    // ),

    // myData?.type === 'INSTITUTION' &&
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`/app/doctors`}>
    //       {t('Doctors')}
    //     </NavLink>,
    //     'doctors',
    //     !topMenu && (
    //       <NavLink className="menuItem-iocn" t0={`/app/doctors`}>
    //         <UilUsersAlt />
    //       </NavLink>
    //     ),
    //   ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`/app/settings`}>
        {t('Settings')}
      </NavLink>,
      'settings',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`/app/settings`}>
          <UilSetting />
        </NavLink>
      ),
    ),

    // isAllowedRole &&
    //   getItem(
    //     <NavLink onClick={() => navigate(`/app/admin`)} to={`/app/admin`}>
    //       {t('Switch to admin')}
    //     </NavLink>,
    //     'Switch to admin',
    //     !topMenu && (
    //       <NavLink className="menuItem-iocn">
    //         <UilEdit />
    //       </NavLink>
    //     ),
    //     null,
    //   ),

    getItem(
      <NavLink onClick={handleLogout}>
        {t('Log')} {t('out')}
      </NavLink>,
      'logout',
      !topMenu && (
        <NavLink className="menuItem-iocn">
          <UilSignout />
        </NavLink>
      ),
    ),
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
      className="bg-[#323541]"
      style={{ backgroundColor: '#323541' }}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
