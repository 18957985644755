import { Button, Input, message, Space, Typography, Col, Row, Form } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { MdSend } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { setCallMessages } from '../../../../redux/chats_messages/actions';
import socketService from '../../../../services/socket.service';
const { Text } = Typography;

const MessageDialogue = ({ setNewCallMessages }) => {
  const { callMessages, callingUser } = useSelector((state) => state.chatsMessages);
  const time = new Date().toISOString();
  const [textMessage, setTextMessage] = useState('');
  const dispatch = useDispatch();
  const messageBodyRef = useRef(null);
  useEffect(() => {
    const element = messageBodyRef.current;
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }, [callMessages]);
  useEffect(() => {
    return () => setNewCallMessages(0);
  }, []);
  const onSubmitMessage = () => {
    if (textMessage.trim().length === 0) return false;

    socketService.emit(
      'call_message',
      {
        message: textMessage,
        receiverId: callingUser?.id,
      },
      (res) => {},
    );
    dispatch(setCallMessages([...callMessages, { text: textMessage, sender: true, createdAt: time }]));
    setTextMessage('');
  };
  return (
    <div
      style={{
        width: '450px',
        backgroundColor: '#fff',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      className="rounded-xl p-4 px-8 sm:w-[80vw]"
    >
      <div className="flex flex-row items-center justify-center font-normal text-xl py-3 mb-6 border-b-1">Chats</div>
      <div
        style={{
          minHeight: 400,
          maxHeight: 450,
          position: 'relative',
        }}
        className="rounded-xl flex flex-col border border-gray-200"
      >
        {callMessages.length > 0 && (
          <div className="mt-1 rounded-6 p-2 overflow-y-auto flex-1" ref={messageBodyRef}>
            <>
              {callMessages
                // .slice(0)
                // .reverse()
                .map((message, i) => (
                  <div
                    //   onStartShouldSetResponder={(e) => {
                    //     startPress = Date.now();
                    //     return true;
                    //   }}
                    //   onResponderRelease={() => {
                    //     const now = Date.now();
                    //     if (startPress && now - startPress > 1000) {
                    //       setSelectedMessage(message);
                    //     }
                    //     startPress = null;
                    //   }}
                    key={i}
                    className="flex flex-row mx-1 my-4"
                    style={{
                      justifyContent: message?.sender ? 'flex-end' : 'flex-start',
                    }}
                  >
                    <div
                      className={
                        message?.sender
                          ? 'max-w-xs p-2 bg-gray-300 rounded-tr-10 rounded-bl-10 relative mb-2'
                          : 'max-w-xs p-2 bg-gray-200 rounded-br-10 rounded-tl-10 relative mb-2'
                      }
                    >
                      {
                        <Text className="text-xs text-gray-500 mb-1 absolute -top-4 opacity-50">
                          {message?.sender ? 'You' : callingUser?.firstName}
                        </Text>
                      }
                      {message?.text !== '' && message?.text != null && (
                        <Text
                          style={{
                            color: '#000',
                            fontSize: 13,
                          }}
                        >
                          {message?.text}
                        </Text>
                      )}
                      <div className="flex flex-row items-center justify-end">
                        <Text
                          style={{
                            color: '#000',
                            fontSize: 10,
                            opacity: 0.5,
                          }}
                        >
                          {moment(message?.createdAt).format('h:mm a')}
                        </Text>
                      </div>
                    </div>
                  </div>
                ))}
              {/* {isTyping && (
          <div
            className={classes.messageRow}
            style={{
              justifyContent: "flex-start",
            }}
          >
            <div className={classes.messageContainer}>
              <Text style={{ color: "#C0C0C0", fontSize: 9 }}>
                ...typing...
              </Text>
            </div>
          </div>
        )} */}
            </>
          </div>
        )}
      </div>

      <Row gutter={16} className="flex justify-between border-t-1 pt-3">
        <Col span={24} className="flex gap-1">
          <Input
            value={textMessage}
            className="rounded-full"
            placeholder="Message"
            onChange={({ target }) => setTextMessage(target.value)}
          />

          <Button
            className="bg-primary hover:bg-white hover:text-primary border-solid border-1 border-primary text-white dark:text-white87 text-[14px] font-semibold items-center rounded-full p-[13px] w-[52px] h-12"
            type="primary"
            size="large"
            onClick={() => onSubmitMessage()}
          >
            <MdSend size={18} />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default MessageDialogue;
