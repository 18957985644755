import { Button, Divider } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { togglePopup } from '../../../redux/globals/actions';

function Prompt({ onVerify, title, body, button, onClose }) {
  const reduxDispatch = useDispatch();
  const loading = useSelector((state) => state.app.promptLoading);

  return (
    <div
      style={{ width: '400px', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
      className="bg-white border rounded-xl p-4 px-8 sm:w-[80vw]"
    >
      <h4 className="text-lg font-medium w-full text-center border-b-1 pb-3">{title}</h4>

      <p className="text-center py-10">{body}</p>

      <div className="flex justify-between border-t-1 pt-3">
        <Button
          className="bg-primary hover:bg-white hover:text-primary border-solid border-1 border-primary text-white dark:text-white87 text-[14px] font-semibold leading-[22px] inline-flex items-center justify-center rounded-full px-[20px] py-[5px] w-[100px]"
          type="primary"
          size="large"
          onClick={onVerify}
          loading={loading}
        >
          {loading ? `${button}...` : button}
        </Button>
        <Button
          type="light"
          className="bg-regularBG hover:bg-white hover:text-gray-500 border-solid border-1 text-gray-500 hover:border-gray-500 dark:text-white87 text-[14px] font-semibold leading-[22px] inline-flex items-center justify-center rounded-full px-[20px] py-[5px]"
          size="large"
          onClick={() => {
            reduxDispatch(togglePopup(false));
            onClose();
          }}
        >
          No
        </Button>
      </div>
    </div>
  );
}

export default Prompt;
