import { Spin } from 'antd';
import React, { Suspense } from 'react';
import { Link } from 'react-router-dom';
// import logoImg from '../../../static/img/xo-bridge.png';
import logoImg from '../../../static/img/dicomx-logo-new.png';

const AuthLayout = (WraperContent) => {
  return function () {
    return (
      <Suspense
        fallback={
          <div className="spin flex items-center justify-center h-[calc(100vh-132px)]">
            <Spin />
          </div>
        }
      >
        <div
          style={{ backgroundImage: `url("${require('../../../static/img/admin-bg-light.png')}")` }}
          className="bg-top bg-no-repeat bg-secondary"
        >
          <div className="py-[50px] 2xl:py-[50px] px-[50px] sm:px-[30px]">
            <div className="flex justify-center mb-5">
              <Link to="/">
                <img className="w-40" src={logoImg} alt="DICOM-X Logo" />
              </Link>
            </div>

            <WraperContent />
          </div>
        </div>
      </Suspense>
    );
  };
};

export default AuthLayout;
