import { theme } from './theme/themeVariables';
const pathName = window.location.pathname;

const config = {
  topMenu: false,
  rtl: false,
  mainTemplate: pathName.startsWith('/app/admin') ? 'darkMode' : 'lightMode',
  theme,
};

export default config;
