// import { Slide } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import IncomingCallView from '../../components/Call/components/IncomingCallView';
import { setCallMessages, setCallingUser } from '../../redux/chats_messages/actions';
import {
  setCaller,
  setCalling,
  setCallType,
  setIncomingCall,
  setPopupChildren,
  togglePopup,
} from '../../redux/globals/actions';
import peerService from '../../services/peer.service';
import socketService from '../../services/socket.service';
import { getToken } from '../../utility/helpers';
import { getItem } from '../../utility/localStorageControl';

const useSocketPeer = () => {
  const token = getToken();
  const userId = getItem('userId');
  const dispatch = useDispatch();

  const location = useLocation();
  const [newMessage, setNewMessage] = useState('');
  const [open, setOpen] = React.useState(false);
  const [transition, setTransition] = React.useState(undefined);
  const { callMessages, callingUser } = useSelector((state) => state.chatsMessages);
  const handleShowMessage = (Transition) => {
    console.log(Transition);
    setTransition(() => Transition);
    setOpen(true);
  };
  // function TransitionUp(props) {
  //   return <Slide {...props} direction="down" />;
  // }
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    console.log(callingUser, ' calling usercahnaged');
    socketService.on('call_message', (res) => {
      console.log('receiving. Call messages are', callMessages);
      dispatch(
        setCallMessages([...callMessages, { text: res?.message, sender: false, createdAt: new Date().toISOString() }]),
      );
    });
  }, [callMessages, callingUser]);
  useEffect(() => {
    if (token) {
      socketService.connect(token);
      peerService.init();
      peerService.on('open', (peerId) => {
        socketService.emit('peer_connected', { userId, peerId }, (value) => {
          if (value.status) {
            console.log(value.message, ' with >> ', userId, peerId);
          } else {
            console.log('Something went wrong connecting peer');
          }
        });
      });

      peerService.on('call', (call) => {
        call.on('close', () => {
          console.log('Call should end');
        });
        const data = JSON.parse(`${call.metadata}`);
        dispatch(setCallingUser(data.user));
        dispatch(setCalling(data.user));
        dispatch(setIncomingCall(call));
        dispatch(setCallType(data.callType));
        dispatch(setCaller(false));
        dispatch(togglePopup(true));

        dispatch(setPopupChildren(<IncomingCallView callingUser={data?.user} />));
      });

      peerService.on('disconnected', () => {
        peerService.reconnect();
      });
    }
  }, [token]);

  useEffect(() => {
    socketService.on('message', (res) => {
      if (res && location.pathname !== '/chats') {
        setNewMessage(res?.message);
        // handleShowMessage(TransitionUp);
      }
    });
  }, [location, token]);
  return { open, handleClose, transition, newMessage };
};

export default useSocketPeer;
