import { SET_PROMPT_LOADING, SET_TOGGLE } from './types';

const initialState = {
  toggle: false,
  promptLoading: false,
};

const appReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SET_TOGGLE:
      return {
        ...state,
        toggle: data,
      };
    case SET_PROMPT_LOADING:
      return {
        ...state,
        promptLoading: data,
      };
    default:
      return state;
  }
};
export default appReducer;
