import { message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setChats, setMessagesInView, setMessagingUser } from '../../../redux/chats_messages/actions';
import socketService from '../../../services/socket.service';
import userService from '../../../services/user.service';

const useContactsMessages = () => {
  const dispatch = useDispatch();
  const [textMessage, setTextMessage] = useState('');
  const [sendingText, setSendingText] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [mediaMessage, setMediaMessage] = useState(null);
  const [sendingMedia, setSendingMedia] = useState(false);
  const [fileExt, setFileExt] = useState('');
  const { messagesInView, messagingUser, chats } = useSelector((state) => state.chatsMessages);
  const messageBodyRef = useRef(null);
  useEffect(() => {
    if (messageBodyRef.current) {
      const element = messageBodyRef.current;
      element.scrollTop = element.scrollHeight;
    }
  }, [messagesInView]);

  useEffect(() => {
    const onTyping = (res) => {
      if (messagingUser?.id === res.sender) {
        setIsTyping(res.status);
      } else {
        setIsTyping(false);
      }
    };
    const onReceiveMessage = (res) => {
      if (messagingUser?.id === res?.sender) {
        dispatch(setMessagesInView([res?.message, ...messagesInView]));
        const newChats = chats?.map((chat) =>
          chat?.user?.id === messagingUser?.id
            ? {
                ...chat,
                last_message: res?.message,
                chat: {
                  ...chat?.chat,
                  unseen_messages: 0,
                },
              }
            : chat,
        );
        const chatToMoveTop = newChats?.filter((chat) => chat?.user?.id === messagingUser?.id);

        const otherChats = newChats?.filter((chat) => chat?.user?.id !== messagingUser?.id);
        dispatch(setChats([...chatToMoveTop, ...otherChats]));
      }
    };

    const onSeenMyMessage = (res) => {
      if (res?.status) {
      }
    };

    const userOpenedOurDm = (res) => {};

    socketService?.on('typing', onTyping);
    socketService?.on('message', onReceiveMessage);
    socketService?.on('seen', onSeenMyMessage);
    socketService?.on('open_dm', userOpenedOurDm);

    return () => {
      socketService?.off('typing', onTyping);
      socketService?.off('message', onReceiveMessage);
      socketService?.off('seen', onSeenMyMessage);
      socketService?.off('open_dm', userOpenedOurDm);
    };
  }, [messagingUser, messagesInView]);

  useEffect(() => {
    return () => {
      messagingUser && onEmitTypingAction(messagingUser.id, false);
    };
  }, []);

  const fileUploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    maxCount: 1,
    fileList,
  };

  const fileUploadBtnProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      getBase64(file);
      return false;
    },
    maxCount: 1,
    fileList: [],
  };

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setFileExt(`.${file.name.split('.').pop()}`);

      setMediaMessage(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const onSubmitMessage = (receiverId) => {
    if (textMessage.trim().length === 0) return false;
    let ref;

    setSendingText(true);
    socketService.emit(
      'message',
      {
        text: textMessage,
        receiver: receiverId,
        type: 'text',
      },
      (res) => {
        if (res.status) {
          setSendingText(false);
          setTextMessage('');

          dispatch(setMessagesInView([res?.data, ...messagesInView]));
        } else {
          setSendingText(false);
          message.error('Unable to send message');
        }
      },
    );
  };

  const getMessagesWithUser = (userId) => {
    dispatch(setMessagesInView([]));
    // dispatch(setGettingMessage(true));

    userService
      .getMessagesWithUser(userId)
      .then((res) => {
        if (res?.success === true) {
          dispatch(setMessagesInView(res?.data?.data));
          setOpenChat(true);
        }
      })
      .catch((err) => message.error(err.message))
      .finally(() => {});
  };

  const handleUserMessage = (user) => {
    dispatch(setMessagingUser(user));
    getMessagesWithUser(user?.id);
    setOpenChat(true);
  };

  const onEmitTypingAction = (receiverId, status) => {
    socketService.emit(
      'typing',
      {
        receiver: receiverId,
        status: status,
      },
      (res) => {},
    );
  };
  const onTyping = (e) => {
    setTextMessage(e);
  };

  const onRemoveMedia = () => {
    setFileList([]);
    setMediaMessage(null);
    setFileExt('');
  };
  const onSendMedia = () => {
    if (!mediaMessage || fileExt.trim().length < 1) return toast.error('Attach a valid media file');

    try {
      setSendingMedia(true);

      socketService.emit(
        'message',
        {
          content: mediaMessage,
          receiver: messagingUser?.id,
          type: 'file',
          ext: fileExt,
          ...(textMessage.trim().length > 0 && { text: textMessage }),
        },
        (res) => {
          console.log('status of ==>', res);
          if (res.status === true) {
            onRemoveMedia();
            setTextMessage('');
            dispatch(setMessagesInView([res?.data, ...messagesInView]));

            const newChats = chats?.map((chat) =>
              chat?.user?.id === messagingUser?.id
                ? {
                    ...chat,
                    last_message: res?.data,
                  }
                : chat,
            );
            const chatToMoveTop = newChats?.filter((chat) => chat?.user?.id === messagingUser?.id);

            const otherChats = newChats?.filter((chat) => chat?.user?.id !== messagingUser?.id);

            dispatch(setChats([...chatToMoveTop, ...otherChats]));

            // if (route?.name != "message-screen") {
            //   navigation.goBack();
            // }
            // setRecordedAudio({});
            onRemoveMedia();
          } else {
            toast.error('Unable to send media');
            setSendingMedia(false);
          }
          setSendingMedia(false);
        },
      );
    } catch (error) {
      toast.error(error.message);
      setSendingMedia(false);
    }
  };
  const handleKeyDown = (e, receiverId) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSubmitMessage(receiverId);
    }
  };

  return {
    handleUserMessage,
    onSubmitMessage,
    onTyping,
    onEmitTypingAction,
    textMessage,
    sendingText,
    handleKeyDown,
    openChat,
    setOpenChat,
    messagingUser,
    messagesInView,
    messageBodyRef,
    setIsTyping,
    isTyping,
    fileUploadProps,
    fileList,
    fileUploadBtnProps,
    onSendMedia,
    mediaMessage,
    sendingMedia,
  };
};

export default useContactsMessages;
