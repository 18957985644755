import { SET_CONTACTS, SET_ME_HOSPITAL, SET_ME_RADIOLOGIST, SET_MY_DATA, SET_REGULARS } from './types';

const initialState = {
  contacts: [],
  regulars: [],
  myData: null,
  myRadiologist: null,
  myHospital: null,
};

const usersReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SET_CONTACTS:
      return {
        ...state,
        contacts: data,
      };
    case SET_REGULARS:
      return {
        ...state,
        regulars: data,
      };
    case SET_MY_DATA:
      return {
        ...state,
        myData: data,
      };
    case SET_ME_RADIOLOGIST:
      return {
        ...state,
        myRadiologist: data,
      };
    case SET_ME_HOSPITAL:
      return {
        ...state,
        myHospital: data,
      };

    default:
      return state;
  }
};

export default usersReducer;
