import { SET_CALL_MESSAGES, SET_CALLING_USER, SET_CHATS, SET_MESSAGES_IN_VIEW, SET_MESSAGING_USER } from './types';

const initialState = {
  callMessages: [],
  messagingUser: null,
  callingUser: null,
  messagesInView: [],
  chats: [],
};

const chatsMessagesReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SET_CALL_MESSAGES:
      return {
        ...state,
        callMessages: data,
      };
    case SET_MESSAGING_USER:
      return {
        ...state,
        messagingUser: data,
      };
    case SET_CALLING_USER:
      return { ...state, callingUser: data };
    case SET_MESSAGES_IN_VIEW:
      return { ...state, messagesInView: data };
    case SET_CHATS:
      return { ...state, chats: data };
    default:
      return state;
  }
};

export default chatsMessagesReducer;
