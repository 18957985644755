export const SET_DRAWER = 'SET_DRAWER';
export const SET_DRAWER_CHILDREN = 'SET_DRAWER_CHILDREN';
export const SET_LOCAL_STREAM = 'SET_LOCAL_STREAM';
export const SET_ON_CALL = 'SET_ON_CALL';
export const SET_SHOW_POPUP = 'SET_SHOW_POPUP';
export const SET_POPUP_CHILDREN = 'SET_POPUP_CHILDREN';
export const SET_SOCKET = 'SET_SOCKET';
export const SET_PEER = 'SET_PEER';
export const SET_CALLING = 'SET_CALLING';
export const SET_OUTGOING_CALL = 'SET_OUTGOING_CALL';
export const SET_INCOMING_CALL = 'SET_INCOMING_CALL';
export const SET_CALLER = 'SET_CALLER';
export const SET_REMOTE_STREAM = 'SET_REMOTE_STREAM';
export const SET_REMOTE_PEER = 'SET_REMOTE_PEER';
export const SET_TRACKS = 'SET_TRACKS';
export const SET_CALL_TYPE = 'SET_CALL_TYPE';
