import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, message } from 'antd';
import { AuthFormWrap } from './style';
import { useNavigate } from 'react-router-dom';
import authenticationService from '../../../../services/authentication.service';
import { setMeHospital, setMeRadiologist } from '../../../../redux/users/actions';
import radiologistsService from '../../../../services/radiologists.service';
import { useDispatch, useSelector } from 'react-redux';
import './styles.css';
import AuthLayout from '../Index';
import { setInstitution } from '../../../../utility/helpers';
import institutionService from '../../../../services/institution.service';

function AccountSelect() {
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(null);
  const [institutions, setInstitutions] = useState([]);

  const myData = useSelector((state) => state.user.myData);
  const allowedRoles = ['ADMIN', 'SUPERADMIN'];

  const isAllowedRole = myData && allowedRoles.includes(myData.role);

  const getMeRadiologist = () => {
    radiologistsService
      .getMe()
      .then(({ data }) => {
        console.log(data);
        reduxDispatch(setMeRadiologist(data));
        setInstitutions(data?.institutions);
        if (!data?.institutions?.length && !isAllowedRole) {
          navigate('/app');
          sessionStorage.removeItem('institutionId');
        }
      })
      .catch((error) => {
        console.log(error);
        message.error(error?.errors || error?.message);
      });
  };

  const getMeHospital = () => {
    institutionService
      .getMe()
      .then(({ data }) => {
        reduxDispatch(setMeHospital(data));
      })
      .catch((error) => {
        console.log(error);
        message.error(error?.errors || error?.message);
      });
  };

  useEffect(() => {
    if (myData?.practitioner) {
      getMeRadiologist();
    }
    if (!myData?.practitioner && !isAllowedRole) {
      navigate('/app');
    }
    if (myData?.type === 'INSTITUTION') {
      getMeHospital();
    }
  }, [myData]);

  const handleSubmit = (id) => {
    if (id === 0) {
      setIsLoading(id);
      setTimeout(() => {
        navigate('/app');
        sessionStorage.removeItem('institutionId');
        setIsLoading(null);
      }, 2000);
      return;
    }
    if (id === -1) {
      setIsLoading(id);
      setTimeout(() => {
        navigate('/app/admin');
        sessionStorage.removeItem('institutionId');
        setIsLoading(null);
      }, 2000);
      return;
    }

    setIsLoading(id);
    authenticationService
      .selectAccount(id)
      .then(({ data }) => {
        setInstitution(id);
        navigate('/app');
      })
      .catch((error) => {
        console.log(error);
        message.error(error.errors);
      })
      .finally(() => {
        setIsLoading(null);
      });
  };

  return (
    <Row justify="center">
      <Col xxl={6} xl={8} md={12} sm={18} xs={24} className="mb-[200px]">
        <AuthFormWrap className="mt-6 bg-primary rounded-md dark:bg-white10 dark:shadow-none">
          <Form name="verifyEmail" layout="vertical" onFinish={handleSubmit}>
            <div className="px-5 py-4 text-center border-b border-gray-200 dark:border-white10">
              <h2 className="mb-0 text-xl font-semibold text-light dark:text-white87">Select Account</h2>
            </div>

            {institutions?.length >= 1 ? (
              <p className="m-4 text-sm text-center text-light dark:text-white87">
                You have multiple account profiles on our platform. Please choose the account profile you want to login
                with.
              </p>
            ) : (
              <p className="m-4 text-sm text-center text-light dark:text-white87">
                If you've got more than one account profile on our platform, you'll find them all right here for easy
                access.
              </p>
            )}

            <div className="px-10 py-4 sm:px-6">
              <Form.Item>
                <Button
                  loading={isLoading === 0}
                  className="w-full bg-white hover:bg-primary hover:text-white border-solid border-1 border-primary text-primary dark:text-white87 text-sm font-semibold inline-flex items-center justify-center rounded-full py-[10px]"
                  style={{ backgroundColor: '#fff', color: '#0796a7' }}
                  type="primary"
                  size="large"
                  onClick={() => handleSubmit(0)}
                >
                  {isLoading === 0 ? `Setting up...` : `My Personal Account`}
                </Button>
              </Form.Item>
              {institutions.map((item) => (
                <Form.Item key={item?.id}>
                  <Button
                    loading={isLoading === item?.id}
                    className="w-full bg-white hover:bg-primary hover:text-white border-solid border-1 border-primary text-primary dark:text-white87 text-sm font-semibold inline-flex items-center justify-center rounded-full py-[10px]"
                    style={{ backgroundColor: '#fff', color: '#0796a7' }}
                    type="primary"
                    size="large"
                    onClick={() => handleSubmit(item?.institution?.id)}
                  >
                    {isLoading === item?.id ? `Setting up...` : item?.institution?.name}
                  </Button>
                </Form.Item>
              ))}
              {isAllowedRole && (
                <Form.Item>
                  <Button
                    loading={isLoading === -1}
                    className="w-full bg-white hover:bg-primary hover:text-white border-solid border-1 border-primary text-primary dark:text-white87 text-sm font-semibold inline-flex items-center justify-center rounded-full py-[10px]"
                    style={{ backgroundColor: '#fff', color: '#0796a7' }}
                    type="primary"
                    size="large"
                    onClick={() => handleSubmit(-1)}
                  >
                    {isLoading === -1 ? `Setting up...` : `Go to Admin`}
                  </Button>
                </Form.Item>
              )}
            </div>
          </Form>
        </AuthFormWrap>
      </Col>
    </Row>
  );
}

export default AuthLayout(AccountSelect);
