export const setEmail = (email) => {
  sessionStorage.removeItem('email');
  sessionStorage.setItem('email', email);
};
export const setStudyId = (study) => {
  sessionStorage.removeItem('study');
  sessionStorage.setItem('study', study);
};

export function getEmail() {
  const email = sessionStorage.getItem('email');

  if (email) return email;
  else return null;
}
export function getStudyId() {
  const study = sessionStorage.getItem('study');

  if (study) return study;
  else return null;
}

export function getToken() {
  const token = sessionStorage.getItem('token');

  if (token) return token;
  else return null;
}

export const removeUserSession = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('institutionId');
};

//   export function getUser() {
//     const userStr = sessionStorage.getItem("user");
//     if (userStr) return JSON.parse(userStr);
//     else return null;
//   }

export const setToken = (token) => {
  sessionStorage.removeItem('token');
  sessionStorage.setItem('token', token);
};
export const setInstitution = (id) => {
  sessionStorage.removeItem('institutionId');
  sessionStorage.setItem('institutionId', id);
};

export function getInstitution() {
  const id = sessionStorage.getItem('institutionId');

  if (id) return id;
  else return null;
}

export function sortRegular(arr) {
  const regulars = arr.filter((obj) => obj.isRegular === true);

  const notRegulars = arr.filter((obj) => obj.isRegular !== true);

  const sortedArr = [...regulars, ...notRegulars];

  return sortedArr;
}
