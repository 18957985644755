import { React, useRef } from 'react';
import { Divider } from 'antd';
import moment from 'moment';

function PreviewReport({ values }) {
  const ref = useRef();

  const {
    examInfo,
    findings,
    recommendations,
    impressions,
    phone,
    email,
    practitionerEmail,
    name,
    signature,
    studyInfo,
  } = values || {};
  const mainDicomTags = studyInfo?.study?.orthancInfo?.MainDicomTags;
  const patientMainDicomTags = studyInfo?.study?.orthancInfo?.PatientMainDicomTags;

  return (
    <div>
      <Divider orientation="center p-4">Report Preview</Divider>
      <div ref={ref} className="pt-0 p-10 relative" style={{ width: 700, height: 'auto', minHeight: 750 }}>
        <div className="flex justify-between align-middle">
          <div>
            <p style={{ lineHeight: '100%', fontSize: 12 }}>No. 5, Kwaji Close, Maitama, Abuja, Nigeria.</p>
            <p style={{ lineHeight: '60%', fontSize: 12 }}>+234 904 000 4400</p>
            <p style={{ lineHeight: '60%', fontSize: 12 }}>hello@xolanihealth.com</p>
            <p style={{ lineHeight: '60%', fontSize: 12 }}>www.xolanibridge.com</p>
          </div>
          <span>
            <img src="https://xolanibridge.com/images/xo-bridge-block.png" width="150px" alt="Xolani Bridge Logo" />
          </span>
        </div>
        <hr color="#999" size="1px" style={{ margin: '20px 0' }} />
        <div className="flex justify-between gap-2">
          <div style={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
            <p style={{ fontSize: 12, lineHeight: '80%' }}>
              <span className="font-bold float-left">Patient Name:</span>
              <br />
              <span className="float-left pt-2">{patientMainDicomTags?.PatientName || 'NA'}</span>
            </p>
            <p style={{ fontSize: 12, lineHeight: '80%' }}>
              <span className="font-bold float-left">Patient DOB:</span>
              <br />
              <span className="float-left pt-2">
                {moment(patientMainDicomTags?.PatientBirthDate).format('DD MMM YYYY') || 'NA'}
              </span>
            </p>
            <p style={{ fontSize: 12, lineHeight: '80%' }}>
              <span className="font-bold float-left">Patient Gender:</span>
              <br />
              <span className="float-left pt-2">{patientMainDicomTags?.PatientSex || 'NA'}</span>
            </p>
          </div>
          <div style={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
            <p style={{ fontSize: 12, lineHeight: '80%' }}>
              <span className="font-bold float-left">Study Date:</span>
              <br />
              <span className="float-left pt-2">{moment(mainDicomTags?.StudyDate).format('DD MMM YYYY') || 'NA'}</span>
            </p>
            <p style={{ fontSize: 12, lineHeight: '80%' }}>
              <span className="font-bold float-left">Report Date:</span>
              <br />
              <span className="float-left pt-2">{moment(new Date()).format('DD MMM YYYY')}</span>
            </p>
            <p style={{ fontSize: 12, lineHeight: '80%' }}>
              <span className="font-bold float-left">Referring Physician:</span>
              <br />
              <span className="float-left pt-2">{mainDicomTags?.ReferringPhysicianName || 'NA'}</span>
            </p>
          </div>
        </div>
        <div className="flex justify-between gap-2" style={{ paddingTop: 15 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <hr color="#999" size="1px" style={{ margin: '0' }} />
            <p style={{ fontSize: 12 }}>
              <span className="font-bold float-left">Examination:</span>
              <br />
              <span className="float-left pt-2">{examInfo}</span>
            </p>
          </div>
        </div>
        <p style={{ fontSize: 16, marginTop: 20 }} className="text-center font-bold">
          Diagnostic Imaging Report
        </p>
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 120 }}>
          <p style={{ fontSize: 12 }}>
            <span className="font-bold float-left">Findings:</span>
            <br />
            {findings}
          </p>
          <p style={{ fontSize: 12 }}>
            <span className="font-bold float-left">Impression:</span>
            <br />
            {impressions}
          </p>
          <p style={{ fontSize: 12 }}>
            <span className="font-bold float-left">Recommendation:</span>
            <br />
            {recommendations}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            bottom: 0,
            right: 0,
            left: 0,
            padding: 30,
          }}
        >
          <span>
            <img src={`${signature}`} width="150px" alt={name} crossOrigin />
          </span>
          <hr color="#999" size="1px" width="40%" style={{ margin: '0px 0 10px 0' }} />
          <p style={{ fontSize: 12, lineHeight: '80%' }}>
            <span className="font-bold float-left">Dr {name}</span>
          </p>
          <p style={{ fontSize: 12, lineHeight: '80%' }}>
            <span className="font-bold float-left">{phone}</span>
          </p>
          <p style={{ fontSize: 12, lineHeight: '80%' }}>
            <span className="font-bold float-left">{practitionerEmail}</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default PreviewReport;
