import env from '../env';
import { request } from './api';
const { API_URL, API_URL2 } = env;

const URL = {
  // getInterpreters: `${API_URL}/get-contacts`,
  getRadiologists: `${API_URL2}/admin/practitioners`,
  getRadiologistsPractitioner: `${API_URL2}/practitioner/practitioners`,
  getRadiologistsInstitution: `${API_URL2}/institution/practitioners`,
  getMe: `${API_URL2}/practitioner/me`,
  verifyRadiologist: `${API_URL2}/admin/practitioners`,
  uploadDetails: `${API_URL2}/practitioner`,
  getDashboard: `${API_URL2}/practitioner/dashboard`,
  getRegulars: `${API_URL2}/practitioner/regulars`,
  importRegulars: `${API_URL2}/practitioner/regulars/upload`,
  getTransactions: `${API_URL2}/practitioner/transactions`,
  ratePractitioner: `${API_URL2}/practitioner`,
  uploadCredential: `${API_URL2}/practitioner/credential`,
  cancelVerification: `${API_URL2}/admin/practitioners`,
};

// function getInterpreters() {
//   return request(URL.getInterpreters, {}, 'GET');
// }

function getRadiologist({ online, modality, day, start_price, end_price, time_to, time_from }) {
  return request(
    URL.getRadiologists,
    { interpreter: true, online, modality, day, start_price, end_price, time_to, time_from },
    'GET',
  );
}
function getRadiologistPractitioner({ online, modality, day, start_price, end_price, time_to, time_from }) {
  return request(
    URL.getRadiologistsPractitioner,
    { interpreter: true, online, modality, day, start_price, end_price, time_to, time_from },
    'GET',
  );
}
function getRadiologistInstitution({
  institutionId,
  online,
  modality,
  day,
  start_price,
  end_price,
  time_to,
  time_from,
}) {
  return request(
    URL.getRadiologistsInstitution,
    { institutionId, interpreter: true, online, modality, day, start_price, end_price, time_to, time_from },
    'GET',
  );
}
function getMe() {
  return request(URL.getMe, {}, 'GET');
}
function verifyRadiologist(id) {
  return request(`${URL.verifyRadiologist}/${id}/verify`, {}, 'POST');
}
function cancelVerification({ id, email, subject, content }) {
  return request(`${URL.cancelVerification}/${id}/verify-info`, { email, subject, content }, 'POST');
}

function uploadCredentials(params) {
  return request(URL.uploadDetails, { ...params }, 'POST');
}

function getDashboard() {
  return request(URL.getDashboard, {}, 'GET');
}
function getRegulars() {
  return request(URL.getRegulars, {}, 'GET');
}
function addRegulars(param) {
  return request(URL.getRegulars, { ...param }, 'POST');
}
function getTransactions() {
  return request(URL.getTransactions, {}, 'GET');
}
function ratePractitioner(id, rating, feedback) {
  return request(`${URL.ratePractitioner}/${id}/rating`, { rating, feedback }, 'POST');
}
function importRegulars(data) {
  return request(URL.importRegulars, data, 'POST', {}, { isFormData: true });
}
function uploadCredential(data) {
  return request(URL.uploadCredential, data, 'POST', {}, { isFormData: true });
}

const radiologistsService = {
  // getInterpreters,
  getRadiologist,
  getRadiologistPractitioner,
  getRadiologistInstitution,
  verifyRadiologist,
  uploadCredentials,
  getMe,
  getDashboard,
  getRegulars,
  addRegulars,
  getTransactions,
  ratePractitioner,
  importRegulars,
  uploadCredential,
  cancelVerification,
};

export default radiologistsService;
