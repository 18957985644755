import { SET_PROMPT_LOADING, SET_TOGGLE } from './types';

export const setToggle = (data) => ({
  type: SET_TOGGLE,
  data,
});
export const setPromptLoading = (data) => ({
  type: SET_PROMPT_LOADING,
  data,
});
