import React, { useMemo, useState } from 'react';
import { Button, Col, Row, message, Form, Upload } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ImportIcon from '../../../assets/icons/csv.svg';
import '../styles.css';
import { togglePopup } from '../../../redux/globals/actions';
import { getInstitution } from '../../../utility/helpers';
import institutionService from '../../../services/institution.service';
import userService from '../../../services/user.service';
import radiologistsService from '../../../services/radiologists.service';

function ImportContact({ type }) {
  const reduxDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [error, setError] = useState(null);

  const myHospital = useSelector((state) => state.user.myHospital);
  const selectedInstitution = getInstitution();
  const institutionId = myHospital?.id || selectedInstitution;

  const selectedHeader = {
    contact: 'Contacts',
    regular: 'Regulars',
    doctor: 'Doctors',
  };
  const selectedSub = {
    contact:
      "You can also import your contacts' email in a CSV format, and we'll gladly invite them to join our platform. Please download format below.",
    regular:
      "You can also import your regulars' email in a CSV format, and we'll gladly invite them to join our platform. Please download format below.",
    doctor:
      "You can also import your staffs' email in a CSV format, and we'll gladly invite them to join our platform. Please download format below.",
  };

  const dropZoneStyleHelper = useMemo(() => {
    if (uploadedFile) {
      return { border: `2px dashed grey` };
    }
    if (error) {
      return {
        border: `2px dashed red`,
        color: 'red',
      };
    }
    return { border: '' };
  }, [uploadedFile, error]);

  const props = {
    name: '',
    action: '',
    beforeUpload: (file) => {
      setUploadedFile(file);
      return false;
    },
  };

  const onImport = (e) => {
    e.preventDefault();
    if (!uploadedFile) {
      setError('Please upload a .CSV file');
    } else {
      if (type === 'contact') {
        setIsLoading(true);
        const data = new FormData();
        institutionId && data.append('institutionId', institutionId);
        data.append('data', uploadedFile);
        userService
          .importContact(data)
          .then((response) => {
            message.info(
              'Congratulations! 🎉 We have successfully sent an invite to the list of people you specified.',
              [10],
            );
            reduxDispatch(togglePopup(false));
          })
          .catch((error) => {
            message.error(error?.errors || error?.message);
          })
          .finally(() => setIsLoading(false));
      } else if (type === 'regular') {
        setIsLoading(true);
        const data = new FormData();
        data.append('data', uploadedFile);
        if (institutionId) {
          institutionService
            .importRegulars(data)
            .then((response) => {
              message.info(
                'Congratulations! 🎉 We have successfully sent an invite to the list of people you specified.',
                [10],
              );
              reduxDispatch(togglePopup(false));
            })
            .catch((error) => {
              message.error(error?.errors || error?.message);
            })
            .finally(() => setIsLoading(false));
        } else {
          radiologistsService
            .importRegulars(data)
            .then((response) => {
              message.info(
                'Congratulations! 🎉 We have successfully sent an invite to the list of people you specified.',
                [10],
              );
              reduxDispatch(togglePopup(false));
            })
            .catch((error) => {
              message.error(error?.errors || error?.message);
            })
            .finally(() => setIsLoading(false));
        }
      } else if (type === 'doctor') {
        setIsLoading(true);
        const data = new FormData();
        data.append('data', uploadedFile);

        institutionService
          .importDoctors(data)
          .then((response) => {
            message.info(
              'Congratulations! 🎉 We have successfully sent an invite to the list of people you specified.',
              [10],
            );
            reduxDispatch(togglePopup(false));
          })
          .catch((error) => {
            message.error(error?.errors || error?.message);
          })
          .finally(() => setIsLoading(false));
      }
    }
  };

  return (
    <Form
      layout="vertical"
      name="nest-messages"
      style={{
        width: '400px',
        backgroundColor: '#fff',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      className="rounded-xl p-4 px-8 sm:w-[80vw]"
    >
      <div className="flex flex-row items-center justify-center font-normal text-xl py-3 mb-6 border-b-1">
        Import {selectedHeader[type]}
      </div>
      <p className="text-center mb-6">{selectedSub[type]}</p>
      <Row className="relative">
        <Col
          lg={24}
          sm={24}
          xs={24}
          className="flex justify-center items-center w-full bg-[#f4f5f9] p-2 cursor-pointer"
          style={dropZoneStyleHelper}
        >
          <Upload
            accept=".csv"
            maxCount={1}
            {...props}
            className="flex flex-col justify-center align-middle items-center"
          >
            <img src={ImportIcon} className="w-[50px] m-auto" />
            {error || (
              <p className="text-center text-xs text-gray-500 p-2">
                {uploadedFile ? uploadedFile?.name : 'Click to Upload file'}
              </p>
            )}
          </Upload>
        </Col>
        {uploadedFile && (
          <span
            onClick={(event) => {
              event.preventDefault();
              setUploadedFile(null);
              setError(null);
            }}
            className="pr-2 pt-1 m-0 absolute top-0 right-0 cursor-pointer text-red-500"
            title="Clear"
          >
            &#10006;
          </span>
        )}
      </Row>
      <Row gutter={16}>
        <Col lg={24} sm={24} xs={24} className="mt-6">
          <p className="text-center text-sm">
            <a
              href="https://server.xolanihealth.cloud/public/attachedFiles/1691586512781-472172326-sample.csv"
              download="sample.csv"
              className="text-primary"
            >
              Download format here
            </a>
          </p>
        </Col>
      </Row>
      <Row gutter={16} className="flex justify-between border-t-1 pt-3">
        <Button
          className="bg-primary hover:bg-white hover:text-primary border-solid border-1 border-primary text-white dark:text-white87 text-sm font-semibold leading-[22px] inline-flex items-center justify-center rounded-full px-[20px] py-[5px]"
          type="primary"
          size="large"
          onClick={onImport}
          loading={isLoading}
        >
          {isLoading ? 'importing...' : 'Import'}
        </Button>

        <Button
          size="large"
          onClick={() => reduxDispatch(togglePopup(false))}
          type="light"
          className="bg-regularBG hover:bg-white hover:text-gray-500 border-solid border-1 text-gray-500 hover:border-gray-500 dark:text-white87 text-sm font-semibold leading-[22px] inline-flex items-center justify-center rounded-full px-[20px] py-[5px]"
        >
          Cancel
        </Button>
      </Row>
    </Form>
  );
}

export default ImportContact;
