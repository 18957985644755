import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../../static/img/xo-bridge-white-v3.png';
import videoSource from '../../../assets/img/Block-1-New-2.mp4';
import feature1 from '../../../static/img/filters_format.webp';
import feature2 from '../../../static/img/filters_format2.webp';
import feature3 from '../../../static/img/filters_format3.webp';
import feature4 from '../../../static/img/filters_format4.svg';
import feature5 from '../../../static/img/filters_format5.svg';
import feature6 from '../../../static/img/filters_format6.svg';
import radiologist from '../../../static/img/filters_format7.webp';
import hospitals from '../../../static/img/filters_format5.webp';
import accessible from '../../../static/img/xo-accessible.png';
import why from '../../../static/img/xo-why.png';
import join from '../../../static/img/xo-join.png';
import contact from '../../../static/img/xo-contact.png';
import enhance from '../../../static/img/xo-enhance.png';

import { Button, Col, Row } from 'antd';
import './style.css';
import { Link } from 'react-scroll';
import { RiMenu5Fill } from 'react-icons/ri';
import { IoCloseCircleOutline } from 'react-icons/io5';

function RadHome() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  function toggleMenu() {
    var menuToggle = document.querySelector('.nav-open');
    var navigation = document.querySelector('.nav-content');
    menuToggle.classList.toggle('active');
    navigation.classList.toggle('active');
  }

  function onClose() {
    var menuToggle = document.querySelector('.nav-open');
    var navigation = document.querySelector('.nav-content');
    menuToggle.classList.remove('active');
    navigation.classList.remove('active');
  }

  const [activeCardIndex, setActiveCardIndex] = useState(null);

  const handleReadMoreClick = (index) => {
    setActiveCardIndex(index === activeCardIndex ? null : index);
  };

  const featuresData = [
    {
      title: 'Work Remotely, on Your Terms',
      content:
        'No more long commutes, get more use for your time. With the Xolani Bridge platform, you can work from the comfort of your own home or any location that suits you best. Own your time hours and drive a career that fits your needs and personal commitments.',
      icon: feature1,
    },
    {
      title: 'Increase Your Earning Potential',
      content:
        'Radiologists on the Xolani Bridge platform earn more through our competitive compensation structure. Xolani Bridge guarantees a constant stream of cases eliminating the need to rely solely on hospital or clinic employment.',
      icon: feature2,
    },
    {
      title: 'Work with a Team of Subspecialist Radiologists',
      content:
        'Collaboration is at the heart of our platform. Join a community of subspecialist radiologists who share your passion for excellence in medical imaging interpretation. Gain exposure to diverse cases and enhance your professional growth through peer-to-peer learning, second opinion reviews and knowledge sharing.',
      icon: feature3,
    },
    {
      title: 'Make a Difference in Patient Care',
      content:
        'By joining our platform, you can expand your impact to underserved communities and patients across Nigeria. Help healthcare institutions and diagnostic centers improve their services and contribute to better patient outcomes.',
      icon: feature4,
    },
    {
      title: 'State-of-the-Art Technology',
      content:
        'Our platform harnesses the latest innovations in medical image processing and telecommunication, ensuring you have access to cutting-edge tools for seamless and safe image transfer, swift turnaround times, and secure communication channels. With XolaniBridge, you can confidently deliver high-quality diagnoses backed by advanced technology and robust resources.',
      icon: feature5,
    },
    {
      title: 'Streamlined Productivity',
      content:
        'Leave behind administrative burdens and embrace a streamlined workflow. Our platform automates processes, allowing you to prioritize what truly matters – delivering accurate and timely diagnoses. XolaniBridge optimizes efficiency, empowering you to maximize your productivity and focus on providing exceptional care.',
      icon: feature6,
    },
  ];
  return (
    <div className="bg-[#EBF6F8]">
      <div>
        {/* Navigation bar section */}
        <div class="flex justify-center fixed top-0 w-full bg-[#0796A7] h-[70px] shadow-md z-50 nav-top">
          <div className="px-8 sm:px-2 md:px-5 lg:px-3 flex justify-between md:justify-between xl:justify-between items-center text-[#FFF] w-full nav-box">
            <div className="pr-12  pl-4">
              <div onClick={() => navigate('/')}>
                <img className="w-full max-w-[150px] min-w-[100px] xs:max-w-[100px]" src={Logo} alt="" />
              </div>
            </div>

            <div className="flex justify-center items-center gap-[40px] text-[1.0rem] font-light sm:text-sm nav-content lg:shadow-2xl">
              <div
                onClick={() => {
                  navigate('/');
                }}
                className="menu-item transition-transform hover:scale-105 hover:text-[#000000] cursor-pointer"
              >
                Home
              </div>

              <Link className="text-white" to="how" smooth={true} duration={500} spy={true} onClick={() => onClose()}>
                <div className="menu-item transition-transform hover:scale-105 hover:text-[#000000] cursor-pointer">
                  How it Works
                </div>
              </Link>

              <Link
                className="text-white"
                to="features"
                smooth={true}
                duration={500}
                spy={true}
                onClick={() => onClose()}
              >
                <div className="menu-item transition-transform hover:scale-105 hover:text-[#000000]  cursor-pointer">
                  Features
                </div>
              </Link>

              <div
                onClick={() => {
                  navigate('/institution');
                }}
                className="menu-item transition-transform hover:scale-105 hover:text-[#000000] cursor-pointer"
              >
                Medical Institutions
              </div>

              {/* <Link className="text-white" to="faqs" smooth={true} duration={500} spy={true} onClick={() => onClose()}>
                <div className="menu-item transition-transform hover:scale-105 hover:text-[#000000] cursor-pointer">
                  FAQs
                </div>
              </Link> */}

              <div className="menu-item transition-transform hover:scale-105">
                <Button
                  size="large"
                  onClick={() => navigate('/app/auth')}
                  className="bg-white hover:text-gray-800 hover:bg-white border-solid border-1 border-primary text-primary dark:text-white87 text-[14px] font-semibold leading-[22px] inline-flex items-center justify-center rounded-full px-5 py-[5px]"
                >
                  Sign In
                </Button>
              </div>
              <div className="nav-close" onClick={() => toggleMenu()}>
                <IoCloseCircleOutline />
              </div>
            </div>

            <div className="nav-open" onClick={() => toggleMenu()}>
              <RiMenu5Fill />
            </div>
          </div>
        </div>

        <div
          className="flex pt-44 pb-12 justify-center items-center flex-col w-full bg-fixed bg-top"
          style={{ backgroundImage: `url("${require('../../../static/img/world-map.png')}")` }}
        >
          <div className="flex flex-col justify-center items-center gap-[30px] p-5 sm:w-full md:w-full lg:w-full w-[70%]">
            <div className="px-20 sm:px-0">
              <p className="font-semibold text-5xl sm:text-4xl text-gray-700 text-center drop-shadow-lg">
                Unlock your full potentials as&nbsp;
                <br className="sm:hidden" />
                a radiologist with our&nbsp;
                <br />
                tele-radiology platform
              </p>
              <p className="font-semibold text-2xl text-gray-700 text-center px-60 sm:px-3 lg:px-32 xl:px-40 drop-shadow-lg">
                Work Remotely, Earn More, and Impact in Patient Care
              </p>
            </div>

            <Link smooth={true} duration={500} to="join">
              <Button
                size="large"
                className="bg-primary hover:bg-white hover:text-primary border-solid border-1 border-primary text-white dark:text-white87 text-[16px] font-semibold leading-[22px] inline-flex items-center justify-center rounded-full px-14 py-[5px]"
              >
                Get Started
              </Button>
            </Link>
          </div>
        </div>

        <div
          id="how"
          className="flex justify-center align-middle mb-10 sm:mb-10 md:mb-12 py-20 sm:py-10 md:py-10 xl:py-10 px-[200px] sm:px-5 md:px-5 xl:px-[100px] bg-white"
        >
          {/* <p className="uppercase font-semibold text-sm text-gray-700 text-left pb-5 px-2">How it works</p> */}
          <div className="h-[700px] max-w-5xl sm:h-auto md:h-auto xl:h-[550px] rounded-3xl overflow-hidden drop-shadow-2xl shadow-2xl border border-primary">
            {/* <ImageSlider /> */}
            <div className="bg-[#EBF6F8]">
              <video
                loop
                muted
                autoPlay
                className="w-full"
                style={{
                  objectFit: 'cover',
                }}
              >
                <source src={videoSource} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>

        {/* Introduction */}
        <section
          id="intro"
          className="flex flex-col justify-center mb-10 sm:mb-5 md:mb-5 py-20 sm:py-10 md:py-14 sm:px-0 md:px-5 lg:px-[20px] xl:px-[50px] w-full"
        >
          <div className="flex flex-col justify-center align-middle gap-y-2">
            {/* <p className="uppercase font-semibold text-sm text-gray-700 text-center pb-5">About Xolani Bridge</p> */}
            <div className="flex md:flex-col justify-between align-middle px-16 sm:px-0 md:px-3 lg:px-0 xl:px-0">
              <div className="px-3 pt-0 lg:px-[20px] w-1/2 sm:w-full md:w-full">
                <h1 className="font-semibold text-3xl sm:text-2xl lg:text-[28px] text-gray-700 text-left">
                  Enhance your radiology service with us
                </h1>
                <p className="font-normal text-gray-700 text-2xl sm:text-xl md:text-xl lg:text-[18px] text-left">
                  Are you a radiologist seeking to enhance your work-life balance, increase earning potential, increase
                  your chances to make a real impact in teaching and patient care? Look no further. Xolani Bridge
                  Tele-radiology is thoughtfully designed to empower radiologists like you to attain these aspirations
                  and beyond. designed to empower radiologists like you to achieve all of this and more. of radiology
                  excellence.
                </p>
              </div>
              <div className="p-3 w-[500px] sm:w-full md:w-full lg:w-[450px]">
                <img className="w-full drop-shadow-md" src={enhance} alt="" />
              </div>
            </div>
          </div>
        </section>

        {/* Benefits */}
        <section
          id="features"
          className="flex flex-col justify-center mb-10 sm:mb-10 md:mb-12 py-16 sm:py-8 md:py-10 sm:px-0 md:px-5 lg:px-[20px] xl:px-[50px] bg-white"
        >
          <div className="flex flex-col justify-between align-middle px-20 sm:px-5 md:px-8 xl:px-0 sm:w-full md:w-full gap-y-2">
            <p className="uppercase font-semibold text-sm text-gray-700 text-center pb-5">Benefits</p>
            {/* <h1 className="font-semibold text-3xl sm:text-2xl lg:text-[28px] text-gray-700 text-left">
              Empowering seamless tele-radiology solutions
              <br />
              with advanced features
            </h1>

            <p className="font-normal text-gray-700 max-w-4xl text-2xl sm:text-xl md:text-xl lg:text-[18px] text-left">
              Introducing our comprehensive set of features tailored to enhance healthcare accessibility and streamline
              tele-radiology services.
            </p> */}
          </div>

          <div className="py-5 px-20 sm:px-5 md:px-8 xl:px-0 lg:px-[20px] sm:w-full md:w-full gap-y-2">
            <Row gutter={25} className="flex justify-center align-middle">
              {featuresData?.map((item, index) => (
                <Col xs={24} sm={24} md={12} xl={8} xxl={8}>
                  <div className="rounded-3xl overflow-hidden shadow-md bg-[#EBF6F8] p-6 mb-8 min-h-[300px]">
                    <div className="rounded-2xl px-3 pb-2">
                      <div className="flex justify-end">
                        <img className="w-full max-w-[50px] xs:max-w-[50px]" src={item?.icon} alt="" />
                      </div>
                      <div className="flex flex-col gap-y-2">
                        <h1 className="font-semibold text-2xl text-gray-700 text-left pt-6">{item?.title}</h1>
                      </div>
                      <p className="font-normal text-gray-700 text-2xl sm:text-xl md:text-xl lg:text-[18px] text-left">
                        {activeCardIndex === index ? item?.content : item?.content.slice(0, 90) + '... '}

                        <span>
                          {item?.content?.length > 90 && (
                            <Button
                              onClick={() => handleReadMoreClick(index)}
                              className="bg-transparent text-sm border-0 hover:bg-transparent hover:text-primary hover:font-semibold p-2"
                            >
                              {activeCardIndex === index ? 'Read less' : 'Read more'}
                            </Button>
                          )}
                        </span>
                      </p>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </section>

        {/* Join Xolani Bridge */}
        <section
          id="join"
          className="flex flex-col justify-center mb-10 sm:mb-5 md:mb-5 py-20 sm:py-10 md:py-14 sm:px-0 md:px-5 lg:px-[20px] xl:px-[50px] w-full"
        >
          <div className="flex flex-col justify-center align-middle gap-y-2">
            {/* <p className="uppercase font-semibold text-sm text-gray-700 text-center pb-5">Why Xolani Bridge</p> */}
            <div className="flex flex-col justify-center align-middle px-16 sm:px-0 md:px-3 lg:px-0 xl:px-0">
              <div className="p-3 w-[700px] m-auto sm:w-full md:w-full lg:w-[450px]">
                <img className="w-full drop-shadow-md" src={join} alt="Join us" />
              </div>
              <div className="px-3 mt-12 lg:px-[20px] w-full">
                <h1 className="font-semibold text-3xl sm:text-2xl lg:text-[28px] text-gray-700 text-center">
                  Join our Tele Radiology Platform Today
                </h1>
                <p className="font-normal text-gray-700 text-2xl sm:text-xl md:text-xl lg:text-[18px] text-center">
                  <Button
                    className="bg-primary hover:bg-white hover:text-primary border-solid border-1 border-primary text-white dark:text-white87 text-[16px] font-semibold leading-[22px] inline-flex items-center justify-center rounded-full px-14 py-[5px] animate-pulse-button"
                    style={{ backgroundColor: '#0796a7' }}
                    size="large"
                    onClick={() => navigate('/app/auth/register')}
                  >
                    Register Now
                  </Button>
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Footer */}
        <section
          id="footer"
          className="flex flex-col justify-center mb-0 py-10 sm:py-10 md:py-0 xl:py-0 px-20 sm:px-5 md:px-5 bg-[#343943] w-full"
        >
          <div className="flex flex-col justify-center align-middle gap-y-2 mt-20 sm:mt-10 md:mt-10 pb-20 sm:pb-10">
            <span className="uppercase font-semibold text-sm text-white60 text-left">Xolani Bridge</span>
            <div className="mt-5">
              <h1 className="font-semibold text-2xl text-primary text-left pt-6 sm:pt-3">Quick Navigation</h1>
              <Button
                className="px-3 h-12 bg-transparent text-white60 hover:bg-transparent hover:text-primary border-white60 hover:border-primary py-0 m-2 text-sm font-medium"
                size="small"
                onClick={() => navigate('/app/auth')}
              >
                Sign in
              </Button>
              <Link to="about" smooth={true} duration={500}>
                <Button
                  className="px-3 h-12 bg-transparent text-white60 hover:bg-transparent hover:text-primary border-white60 hover:border-primary py-0 m-2 text-sm font-medium"
                  size="small"
                >
                  About
                </Button>
              </Link>

              <Button
                className="px-3 h-12 bg-transparent text-white60 hover:bg-transparent hover:text-primary border-white60 hover:border-primary py-0 m-2 text-sm font-medium"
                size="small"
                onClick={() => navigate('/radiologist')}
              >
                Radiologists
              </Button>
              <Button
                className="px-3 h-12 bg-transparent text-white60 hover:bg-transparent hover:text-primary border-white60 hover:border-primary py-0 m-2 text-sm font-medium"
                size="small"
                onClick={() => navigate('/institution')}
              >
                Hospitals/Diagnosis center
              </Button>
              <Button
                className="px-3 h-12 bg-transparent text-white60 hover:bg-transparent hover:text-primary border-white60 hover:border-primary py-0 m-2 text-sm font-medium"
                size="small"
                onClick={() => window.open('/privacy', '_blank')}
              >
                Privacy policy
              </Button>
              <Button
                className="px-3 h-12 bg-transparent text-white60 hover:bg-transparent hover:text-primary border-white60 hover:border-primary py-0 m-2 text-sm font-medium"
                size="small"
                onClick={() => window.open('/terms-conditions', '_blank')}
              >
                Terms & Conditions
              </Button>
            </div>

            <div className="mt-5">
              <h1 className="font-semibold text-2xl text-primary text-left pt-6 sm:pt-3">Contact Us </h1>
              <p className="font-semibold text-sm text-white60 text-left">
                Address: No. 5, Kwaji Close, Maitama - Abuja, Nigeria.
              </p>
              <p className="font-semibold text-sm text-white60 text-left">
                <a className="text-white60" href="tel:+2349040004400" target="_blank">
                  Phone: +234 904 000 4400
                </a>
              </p>
              <p className="font-semibold text-sm text-white60 text-left">
                <a className="text-white60" href="mailto:hello@xolanihealth.com" target="_blank">
                  Email: hello@xolanihealth.com
                </a>
              </p>
            </div>
          </div>
        </section>
        <div class="copyright w-full p-2 bg-primary text-black text-center font-bold">
          Copyright 2021 - {new Date().getFullYear()},
          <a className="text-black" href="https://xolanihealth.com" target="_blank">
            {' '}
            Xolani Health Inc.{' '}
          </a>
          All rights reserved.
        </div>
      </div>
    </div>
  );
}

export default RadHome;
