export const days = [
  {
    name: 'Sunday',
    value: 0,
  },
  {
    name: 'Monday',
    value: 1,
  },
  {
    name: 'Tuesday',
    value: 2,
  },
  {
    name: 'Wednesday',
    value: 3,
  },
  {
    name: 'Thursday',
    value: 4,
  },
  {
    name: 'Friday',
    value: 5,
  },
  {
    name: 'Saturday',
    value: 6,
  },
];

export const specializationType = [
  { name: 'RADIOLOGY', value: 'radiology' },
  { name: 'HEAD NECK', value: 'head_neck' },
  { name: 'CHEST IMAGING', value: 'chest_imaging' },
  { name: 'ABDOMINAL IMAGING', value: 'abdominal_imaging' },
  { name: 'NEURO RADIOLOGY', value: 'neuro_radiology' },
  { name: 'FEMALE IMAGING', value: 'female_imaging' },
  { name: 'MUSCULOSKELETAL', value: 'musculoskeletal' },
  { name: 'PEDIATRIC', value: 'pediatric' },
  { name: 'UR AND MALE IMAGING', value: 'ur_and_male_imaging' },
  { name: 'CR AND CARDIAC IMAGING', value: 'cr_and_cardiac_imaging' },
  { name: 'NUCLEAR MEDICINE', value: 'nuclear_medicine' },
  { name: 'PET AND INTER RADIO', value: 'pet_and_inter_radio' },
];

export const accreditationType = [
  { name: 'GMC', value: 'GMC' },
  { name: 'MCIRL', value: 'MCIRL' },
  { name: 'AHPRA', value: 'AHPRA' },
  { name: 'MCNZ', value: 'MCNZ' },
  { name: 'FRANZCR', value: 'FRANZCR' },
  { name: 'OTHER', value: 'OTHER' },
];
