import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const NotFound = lazy(() => import('../../container/pages/404'));
const AdminDashboard = lazy(() => import('../../container/admin'));
const RadiologistTable = lazy(() => import('../../container/admin/radiologist'));
const HospitalTable = lazy(() => import('../../container/admin/hospital'));
const UsersTable = lazy(() => import('../../container/admin/users'));
const StudiesTable = lazy(() => import('../../container/admin/studies'));
const PaymentTable = lazy(() => import('../../container/admin/payments'));
const DoctorsTable = lazy(() => import('../../container/admin/doctors'));

function AdminRoutes() {
  return (
    <Routes>
      <Route index element={<AdminDashboard />} />
      <Route path="radiologist" element={<RadiologistTable />} />
      <Route path="radiologist/:id" element={<PaymentTable />} />
      <Route path="hospitals/:id" element={<PaymentTable isHospital />} />
      <Route path="hospitals" element={<HospitalTable />} />
      <Route path="hospitals/doctors/:id" element={<DoctorsTable />} />
      <Route path="users" element={<UsersTable />} />
      <Route path="studies" element={<StudiesTable />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AdminRoutes;
