import React from 'react';
import { IoPersonCircle } from 'react-icons/io5';
import { Button, Input, message, Space, Typography, Col, Row, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setCaller, setCalling, setIncomingCall, setOnCall, togglePopup } from '../../../../redux/globals/actions';
import { MdPhoneDisabled, MdPhoneEnabled } from 'react-icons/md';
import { setCallingUser, setMessagingUser } from '../../../../redux/chats_messages/actions';
import socketService from '../../../../services/socket.service';

const { Text } = Typography;
const IncomingCallView = ({ callingUser }) => {
  const { incomingCall, socket, callType } = useSelector((state) => state.globals);
  const dispatch = useDispatch();
  const onAnswerCall = () => {
    dispatch(togglePopup(false));
    dispatch(setOnCall(true));
  };

  const onRejectCall = () => {
    incomingCall && incomingCall.close();
    dispatch(setOnCall(false));
    dispatch(setCalling(null));
    dispatch(setCallingUser(null));

    dispatch(setIncomingCall(null));
    dispatch(setCaller(false));
    dispatch(togglePopup(false));
    dispatch(setMessagingUser(null));
    socketService.emit('end_call', callingUser?.id);
  };

  socketService.on('end_call', ({ status, sender }) => {
    if (status && sender === callingUser?.id) {
      onRejectCall();
    }
  });

  return (
    <div
      style={{
        width: '400px',
        backgroundColor: '#fff',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      className="rounded-xl p-4 px-8 sm:w-[80vw] flex flex-col justify-center"
    >
      <div className="flex flex-row items-center justify-center font-normal text-xl py-3 mb-6 border-b-1">
        {`Incoming ${callType} call`}
      </div>

      <IoPersonCircle size={100} className="flex self-center" />
      <p className="text-lg text-center" ellipsis={true} title="User">
        {callingUser?.firstName + ' ' + callingUser?.lastName}
      </p>

      <Row gutter={16} className="flex justify-between border-t-1 pt-3">
        <Col span={24} className="flex justify-between gap-1">
          <Button
            onClick={() => onAnswerCall()}
            title="Answer Call"
            className="rounded-full flex flex-col justify-center items-center p-2 hover:bg-white hover:text-green-500 border border-green-500 bg-green-500 text-white"
          >
            <MdPhoneEnabled size={32} />
          </Button>
          <Button
            onClick={() => onRejectCall()}
            title="Reject Call"
            className="rounded-full flex flex-col justify-center items-center p-2 hover:bg-white hover:text-red-500 border border-red-500 bg-red-500 text-white"
          >
            <MdPhoneDisabled color="white" size={32} />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default IncomingCallView;
