import React, { useEffect, useState, lazy } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { ConfigProvider, message } from 'antd';
import { store, persistor } from './redux/store';
import Admin from './routes/admin';
import Auth from './routes/auth';
import './static/css/style.css';
import config from './config/config';
import ProtectedRoute from './components/utilities/protectedRoute';
import 'antd/dist/antd.less';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getToken, removeUserSession } from './utility/helpers';
import AccountSelect from './container/profile/authentication/overview/AccountSelect';
import HomePage from './container/pages/HomePage';
import RadHome from './container/pages/HomePage/RadHome';
import InstHome from './container/pages/HomePage/InstHome';
import Terms from './container/pages/HomePage/Terms';
import Privacy from './container/pages/HomePage/Privacy';
import { useMemo } from 'react';
import { logOut } from './redux/authentication/actionCreator';
import { PersistGate } from 'redux-persist/integration/react';

const NotFound = lazy(() => import('./container/pages/404'));

const { theme } = config;

function ProviderConfig() {
  const { rtl, isLoggedIn, topMenu, mainContent } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      mainContent: state.ChangeLayoutMode.mode,
      isLoggedIn: state.auth.login,
    };
  });

  const token = getToken();

  function isTokenValid() {
    const token = getToken();

    if (!token) {
      return false;
    }

    return true;
  }

  const isValid = useMemo(() => isTokenValid(), [token]);

  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }

    return () => (unmounted = true);
  }, [setPath]);

  const dispatch = useDispatch();

  const handleLogout = () => {
    removeUserSession();
    dispatch(logOut(() => history('/app/auth')));
  };

  let idleTimer;
  const idleTimeout = 3600000;

  function resetIdleTimer() {
    clearTimeout(idleTimer);
    idleTimer = setTimeout(handleLogout, idleTimeout);
  }

  document.addEventListener('mousemove', resetIdleTimer);
  document.addEventListener('keydown', resetIdleTimer);

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, mainContent }}>
        <ToastContainer style={{ fontSize: '14px', lineHeight: '17px' }} autoClose={3000} pauseOnHover={true} />
        <Router basename={process.env.PUBLIC_URL}>
          <Routes>
            <Route path="/app/auth/*" element={<Auth />} />
            {isValid ? (
              <>
                <Route path="/app/*" element={<ProtectedRoute path="/*" Component={Admin} />} />
                <Route path="/app/selectAccount" element={<AccountSelect />} />
              </>
            ) : (
              <Route path="/*" element={<Navigate to="/app/auth" />} />
            )}
            <Route path="/" element={<HomePage />} />
            <Route path="/radiologist" element={<RadHome />} />
            <Route path="/institution" element={<InstHome />} />
            <Route path="/terms-conditions" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
}

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ProviderConfig />
      </PersistGate>
    </Provider>
  );
}

export default App;
