import React, { useState } from 'react';
import '../../styles.css';
import { MdScreenshotMonitor, MdCancel, MdDownload, MdBookOnline } from 'react-icons/md';
import { BsPersonPlusFill, BsRecordBtn } from 'react-icons/bs';
import { Button, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import useControlPanel from './hooks/useControlPanel';
import { setPopupChildren, togglePopup, setDrawer, setDrawerChildren } from '../../../../redux/globals/actions';
import AddContact from '../../../Contacts/AddContact';
import Reports from '../../../../container/pages/Viewer/components/ViewerReports';
import { useLocation } from 'react-router-dom';
const { Text } = Typography;

const ControlPanel = ({ endCall }) => {
  const [minimized, setMinimized] = useState(false);
  const location = useLocation();

  const dispatch = useDispatch();
  const toggleMinimized = () => {
    setMinimized(!minimized);
  };

  const isStudy = location?.state?.study;

  const isViewer = /^\/app\/viewer(\/\d+)?$/.test(window.location.pathname);

  const myData = useSelector((state) => state.user.myData);
  const isRadiologist = myData?.type === 'PRACTITIONER';

  const isVerified = myData?.practitioner?.verified;
  const {
    state,
    onStartScreenRecording,
    mediaElementRef,
    removeFile,
    handleStopRecording,
    image,
    handleScreenshot,
    screenshotRef,
    downloadScreenshot,
    isCaptured,
  } = useControlPanel();

  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  return (
    <>
      {isCaptured && (
        <div
          className="media-element bg-[#27272b] p-5 rounded-[14px] border border-primary w-96"
          onContextMenu={handleContextMenu}
        >
          {state.videoFile && (
            <video width="100%" height="100%" className="border-primary border" ref={mediaElementRef} controls>
              <source src={URL.createObjectURL(state.videoFile)} type="video/mp4" />
            </video>
          )}
          {state.imageFile && (
            <img width="100%" height="100%" className="border-primary border" src={state.imageFile} />
          )}
          <div className="relative w-full pt-2 flex justify-between">
            {state.imageFile && (
              <Button
                className="bg-primary hover:bg-white hover:text-primary border-solid border-1 border-primary text-white dark:text-white87 text-[14px] font-semibold leading-[22px] inline-flex items-center justify-center rounded-full px-[20px] py-[5px]"
                type="primary"
                size="small"
                title="Download"
                onClick={() => {
                  downloadScreenshot();
                }}
              >
                <MdDownload />
              </Button>
            )}
            {(state.imageFile || state.videoFile) && (
              <Button
                className="bg-red-500 hover:bg-white hover:text-red-500 border-solid border-1 border-red-500 text-white dark:text-white87 text-[14px] font-semibold leading-[22px] inline-flex items-center justify-center rounded-full px-[20px] py-[5px]"
                type="warning"
                size="small"
                title="Remove"
                onClick={() => {
                  removeFile();
                }}
              >
                <MdCancel />
              </Button>
            )}
          </div>
        </div>
      )}

      <div className="control-panel-body shadow-2xl">
        {state.recording && <Text className="absolute -top-4 left-2 text-xs">Recording...</Text>}

        {state.recording ? (
          <Button
            title="Stop Recording"
            className="recording-btn rounded-full flex flex-col justify-center items-center w-8 h-8 text-green-500 border border-green-500"
            onClick={handleStopRecording}
          >
            <BsRecordBtn size={20} />
          </Button>
        ) : (
          <Button
            title="Record Screen"
            className="rounded-full flex flex-col justify-center items-center w-8 h-8 bg-primary border-0"
            onClick={onStartScreenRecording}
          >
            <BsRecordBtn size={20} />
          </Button>
        )}

        <Button
          onClick={handleScreenshot}
          title="Take Screenshot"
          className="rounded-full flex flex-col justify-center items-center w-8 h-8 bg-primary border-0"
        >
          <MdScreenshotMonitor size={20} />
        </Button>
        <Button
          onClick={() => {
            dispatch(togglePopup(true));
            dispatch(setPopupChildren(<AddContact />));
          }}
          title="Add Contact"
          className="rounded-full flex flex-col justify-center items-center w-8 h-8 bg-primary border-0"
        >
          <BsPersonPlusFill size={20} />
        </Button>
        {isViewer && (
          <Button
            onClick={() => {
              dispatch(setDrawer(true));
              dispatch(setDrawerChildren(<Reports />, '50%'));
            }}
            title="Write a report"
            className="rounded-full flex flex-col justify-center items-center w-8 h-8 bg-primary border-0"
          >
            <MdBookOnline size={20} />
          </Button>
        )}
      </div>
    </>
  );
};

export default ControlPanel;
