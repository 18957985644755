import React from 'react';
import './ImageSlide.styles.css';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import Image1 from '../../../assets/img/banner-ircai.png';
import Image2 from '../../../assets/img/banner-jica-visit-2.png';
import Image3 from '../../../assets/img/banner-techstars-v2.png';

const spanStyle = {
  padding: '20px',
  background: 'transparent',
  color: '#fff',
  fontSize: '24px',
  width: '60%',
  transform: 'translate(-20%, 70%)',
};

const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  height: '500px',
};

const ImageSlider = () => {
  const slideImages = [
    {
      url: Image1,
      caption:
        'Xolani Health was also named one of the most promising Artificial Intelligence startups on the IRCAI Global Top 100 international list.',
    },
    {
      url: Image2,
      caption:
        'Xolani Health founding team with Senior Vice President Japan International Cooperation Agency (JICA) during her recent visit to Abuja.',
    },
    {
      url: Image3,
      caption:
        'Xolani Health was selected as one of the 12 startups to participate in the Techstars Tel-Aviv accelerator program in March 2023.',
    },
  ];

  const properties = {
    duration: 5000,
    autoplay: true,
    transitionDuration: 500,
    arrows: true,
    infinite: true,
    easing: 'ease',
    // indicators: (i) => <div className="indicator">{i + 1}</div>,
  };
  return (
    <div style={{ height: '100% !important' }} className="mainSlider relative">
      <Slide {...properties}>
        {slideImages.map((slideImage, index) => (
          <div key={index}>
            <div style={{ ...divStyle, backgroundImage: `url(${slideImage.url})` }}>
              <span style={spanStyle}>{slideImage.caption}</span>
            </div>
          </div>
        ))}
      </Slide>
    </div>
  );
};

export default ImageSlider;
