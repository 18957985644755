import { SET_CONTACTS, SET_ME_HOSPITAL, SET_ME_RADIOLOGIST, SET_MY_DATA, SET_REGULARS } from './types';

export const setContacts = (data) => ({
  type: SET_CONTACTS,
  data,
});
export const setRegulars = (data) => ({
  type: SET_REGULARS,
  data,
});

export const setMyData = (data) => ({
  type: SET_MY_DATA,
  data,
});
export const setMeRadiologist = (data) => ({
  type: SET_ME_RADIOLOGIST,
  data,
});
export const setMeHospital = (data) => ({
  type: SET_ME_HOSPITAL,
  data,
});
