import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Form, Input, Button, Row, Col, message } from 'antd';
import { AuthFormWrap } from './style';
import axios from 'axios';
import { getItem } from '../../../utility/localStorageControl';
import userService from '../../../services/user.service';
import institutionService from '../../../services/institution.service';

function ConfirmInvite({ isDoctor }) {
  const [loading, setLoading] = useState(false);
  const history = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const referrer = params.get('ref');
  const userRef = params.get('userRef');

  const institutionRef = params.get('institution');
  const practitionerRef = params.get('user');

  const handleSubmit = () => {
    setLoading(true);
    userService
      .confirmInvites({ referrer, userRef })
      .then((data) => {
        message.success(data.message);
        setTimeout(() => {
          history('/app');
        }, 4000);
      })
      .catch((error) => {
        message.error(error?.errors || error?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleDoctors = () => {
    setLoading(true);
    institutionService
      .confirmInvites({ institutionRef, practitionerRef })
      .then((data) => {
        message.success(data.message);
        setTimeout(() => {
          history('/app');
        }, 2000);
      })
      .catch((error) => {
        message.error(error?.errors || error?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Row justify="center">
      <Col xxl={6} xl={8} md={12} sm={18} xs={24}>
        <AuthFormWrap className="mt-6 bg-white rounded-md dark:bg-white10 shadow-regular dark:shadow-none">
          <Form name="forgotPass" layout="vertical">
            <div className="px-5 py-4 text-center border-b border-gray-200 dark:border-white10">
              <h2 className="mb-0 text-xl font-semibold text-dark dark:text-white87">Accept Invitation</h2>
            </div>
            <div className="px-10 pt-8 pb-6 sm:px-6">
              <p className="mb-4 dark:text-white60">Please click on the button to accept the invite.</p>
              <Form.Item>
                <Button
                  className="block w-full h-12 p-0 text-sm font-medium"
                  htmlType="submit"
                  type="primary"
                  size="large"
                  loading={loading}
                  onClick={() => (isDoctor ? handleDoctors() : handleSubmit())}
                >
                  {loading ? 'Working...' : 'Accept now'}
                </Button>
              </Form.Item>
            </div>
            <div className="p-6 text-center bg-section dark:bg-white10 rounded-b-md">
              <p className="mb-0 text-sm font-medium text-body dark:text-white60">
                Return to
                <Link to="/" className="ltr:ml-1.5 rtl:mr-1.5 text-primary hover:text-primary">
                  Sign In
                </Link>
              </p>
            </div>
          </Form>
        </AuthFormWrap>
      </Col>
    </Row>
  );
}

export default ConfirmInvite;
