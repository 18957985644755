import { Button, Input, Col, Row, Typography, Form, TimePicker, Select, Table } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import '../styles.css';
import { togglePopup } from '../../../redux/globals/actions';
import { days } from '../../../container/profile/settings/overview/Information/constants';
import Heading from '../../heading/heading';
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const columns = [
  {
    title: 'Day',
    dataIndex: 'day',
    width: '20px',
  },
  {
    title: 'Time',
    dataIndex: 'time',
    width: '100px',
  },
];

const data = [
  {
    key: '1',
    day: (
      <div className="flex flex-col justify-center align-middle items-start">
        <div className="h-[34px]">
          <Form.Item name={`day-${1}`} className="p-0 m-0">
            <Select
              defaultValue={1}
              disabled
              size="small"
              onChange={(value) => handleDayChange(1, value)}
              className="sm:w-28"
            >
              {days.map((val) => (
                <Select.Option value={val?.value} key={val?.value}>
                  {val.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </div>
    ),
    time: (
      <div className="flex flex-col justify-center align-middle items-start">
        <div className="h-[34px]">
          <Form.Item name={`hours-${1}`}>
            <TimePicker.RangePicker
              size="small"
              onChange={(value) => handleHoursChange(1, value)}
              defaultValue={null}
              className="w-44"
            />
          </Form.Item>
        </div>
      </div>
    ),
  },
  {
    key: '2',
    day: (
      <div className="flex flex-col justify-center align-middle items-start">
        <div className="h-[34px]">
          <Form.Item name={`day-${2}`} className="p-0 m-0">
            <Select
              defaultValue={3}
              disabled
              size="small"
              onChange={(value) => handleDayChange(3, value)}
              className="sm:w-28"
            >
              {days.map((val) => (
                <Select.Option value={val?.value} key={val?.value}>
                  {val.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </div>
    ),
    time: (
      <div className="flex flex-col justify-center align-middle items-start">
        <div className="h-[34px]">
          <Form.Item name={`hours-${1}`}>
            <TimePicker.RangePicker
              size="small"
              onChange={(value) => handleHoursChange(1, value)}
              defaultValue={null}
              className="w-44"
            />
          </Form.Item>
        </div>
      </div>
    ),
  },
];

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === 'Disabled User',
    name: record.name,
  }),
};

const ScheduleMeeting = (user) => {
  const reduxDispatch = useDispatch();
  return (
    <Form
      {...layout}
      layout="vertical"
      name="nest-messages"
      onFinish={null}
      style={{
        width: '500px',
        backgroundColor: '#fff',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      className="rounded-xl p-4 px-8 sm:w-[80vw]"
    >
      <div className="flex flex-row items-center justify-center font-normal text-xl py-3 mb-6 border-b-1">
        Schedule Meeting
      </div>
      <p className="text-center">
        Interested in planning a meeting with {user?.user?.firstName || ' '} {user?.user?.lastName || ' '} for a later
        time? Pick your preferred dates from the options below.
      </p>
      <Row gutter={16}>
        <Col lg={24} sm={24} xs={24}>
          <Heading as="h6" className="mb-4 mt-8 text-gray-500 text-[14px] font-normal">
            Available date(s) for {user?.user?.firstName || ' '} {user?.user?.lastName || ' '}.
          </Heading>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24} xs={24} xl={24}>
          <Table
            rowSelection={{
              type: 'radio',
              ...rowSelection,
            }}
            columns={columns}
            dataSource={data}
            locale={{
              emptyText: 'There are no data found 🔎',
            }}
            scroll={{ x: true }}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={24} sm={24} xs={24}>
          <Form.Item
            name="title"
            label="Meeting title"
            className="[&>div>div>label]:text-sm [&>div>div>label]:text-gray-500 dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col lg={24} sm={24} xs={24}>
          <Form.Item
            name="description"
            label="Meeting description"
            className="[&>div>div>label]:text-sm [&>div>div>label]:text-gray-500 dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} className="flex justify-between border-t-1 pt-3">
        <Button
          className="bg-primary hover:bg-white hover:text-primary border-solid border-1 border-primary text-white dark:text-white87 text-[14px] font-semibold leading-[22px] inline-flex items-center justify-center rounded-full px-[20px] py-[5px]"
          type="primary"
          size="large"
          htmlType="submit"
          loading={false}
        >
          {true ? 'Schedule' : 'Scheduling...'}
        </Button>

        <Button
          size="large"
          onClick={() => reduxDispatch(togglePopup(false))}
          type="light"
          className="bg-regularBG hover:bg-white hover:text-gray-500 border-solid border-1 text-gray-500 hover:border-gray-500 dark:text-white87 text-[14px] font-semibold leading-[22px] inline-flex items-center justify-center rounded-full px-[20px] py-[5px]"
        >
          Cancel
        </Button>
      </Row>
    </Form>
  );
};

export default ScheduleMeeting;
