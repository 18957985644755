import { Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import { DrawerStyle } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { setDrawer, setDrawerChildren } from '../../redux/globals/actions';

function Drawer() {
  const { drawer, drawerChildren, size } = useSelector((state) => state.globals);
  const dispatch = useDispatch();

  useEffect(() => {
    if (drawer === false) {
      dispatch(setDrawerChildren(null));
    }
  }, [drawer]);

  const onClose = () => {
    dispatch(setDrawer(false));
  };

  const [drawerWidth, setDrawerWidth] = useState('500px');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        setDrawerWidth('80vw');
      } else {
        setDrawerWidth(size || '500px');
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [size]);

  return (
    <DrawerStyle width={drawerWidth} placement={'right'} closable={false} onClose={onClose} open={drawer}>
      {drawerChildren}
    </DrawerStyle>
  );
}

export { Drawer };
