import React, { lazy, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import ConfirmInvite from '../container/pages/ConfirmInvite';
import AuthLayout from '../container/profile/authentication/Index';

const Login = lazy(() => import('../container/profile/authentication/overview/SignIn'));
const SignUpRadio = lazy(() => import('../container/profile/authentication/overview/SignupRadio'));
const ForgotPass = lazy(() => import('../container/profile/authentication/overview/ForgotPassword'));
const Verify = lazy(() => import('../container/profile/authentication/overview/Verify'));
const ResetPass = lazy(() => import('../container/profile/authentication/overview/ResetPassword'));
const TwoFA = lazy(() => import('../container/profile/authentication/overview/TFA'));

const AuthRoot = () => {
  const navigate = useNavigate();

  useEffect(() => navigate('/auth'));
};

const FrontendRoutes = React.memo(() => {
  return (
    <Routes>
      <Route index element={<Login />} />
      <Route path="forgotPassword" element={<ForgotPass />} />
      <Route path="verify" element={<Verify />} />
      <Route path="verifyOtp" element={<Verify isPassword />} />
      <Route path="confirm-invite" element={<ConfirmInvite />} />
      <Route path="practitioner-invite" element={<ConfirmInvite isDoctor />} />
      <Route path="resetPassword" element={<ResetPass />} />
      <Route path="register" element={<SignUpRadio />} />
      <Route path="register-institution" element={<SignUpRadio isInstitution />} />
      <Route path="twoFA" element={<TwoFA />} />
      <Route path="*" element={<AuthRoot />} />
    </Routes>
  );
});

export default AuthLayout(FrontendRoutes);
