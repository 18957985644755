import { message } from 'antd';
import axios from 'axios';
import React, { useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCallingUser, setMessagingUser } from '../../../redux/chats_messages/actions';
import {
  setCaller,
  setCalling,
  setCallType,
  setDrawer,
  setOnCall,
  setPopupChildren,
  setRemotePeer,
  togglePopup,
} from '../../../redux/globals/actions';
import { setContacts } from '../../../redux/users/actions';
import userService from '../../../services/user.service';
import { getItem } from '../../../utility/localStorageControl';
import Prompt from '../../../container/admin/component/Prompt';
import { setPromptLoading } from '../../../redux/app/actions';
import peerService from '../../../services/peer.service';
import socketService from '../../../services/socket.service';

const useContacts = () => {
  const contacts = useSelector((state) => state.user.contacts);
  const { socket, peer } = useSelector((state) => state.globals);
  const user = getItem('user');
  const userEmail = getItem('email');
  const initialState = {
    addingContact: false,
    email: '',
    name: '',
    note: '',
  };

  const [state, dispatch] = useReducer((newState, value) => ({ ...newState, ...value }), { initialState });

  const reduxDispatch = useDispatch();

  const getContacts = () => {
    userService
      .getContacts()
      .then(({ data }) => {
        reduxDispatch(setContacts(data));
      })
      .catch((error) => console.log(error))
      .finally(() => {});
  };

  const onCallUser = (user, type) => {
    socketService.emit('call', user?.id, ({ status, data }) => {
      if (!status) {
        message.error('Something went wrong');
      }

      if (data.peerId) {
        reduxDispatch(setRemotePeer(data.peerId));
        reduxDispatch(setMessagingUser(user.id));
        try {
          reduxDispatch(setCalling(user));
          reduxDispatch(setCallingUser(user));
          reduxDispatch(setCallType(type));
          reduxDispatch(setCaller(true));

          reduxDispatch(setOnCall(true));
          reduxDispatch(setDrawer(false));

          peerService.on('error', (err) => {
            console.log(err);
          });
        } catch (error) {
          message.error(error?.message);
        }
      } else {
        message.error('User is not connected');
      }
    });
  };

  const handleAddContact = () => {
    dispatch({ addingContact: true });

    userService
      .addContact({
        email: state.email,
        name: state.name,
      })
      .then((data) => {
        message.success(data?.message);
        reduxDispatch(togglePopup(false));
      })
      .catch((error) => {
        message.error(error?.errors || error?.message);
      })
      .finally(() => {
        dispatch({ addingContact: false });
      });
  };

  // if (call) {
  //   // InCallManager.start({
  //   //   media: callType == "voice" ? "audio" : "video",
  //   //   ringback: "_DTMF_",
  //   // });
  //   // InCallManager.setForceSpeakerphoneOn(true);
  //   call.on("error", (err = new Error()) => {
  //     console.log(err.message, "on call error");
  //   });
  //   setCalling(true);
  //   setOutgoingCall(call);
  //   setCaller(true);
  //   call.on("stream", (rStream) => {
  //     //   InCallManager.stopRingback();

  //     setOnCall(true);
  //     setCalling(false);
  //     setRemoteStream(rStream);
  //     remoteStreamRef.current.srcObject = rStream;
  //   });

  //   call.on("close", () => {
  //     endCall(call);
  //     console.log("Call should end");
  //   });
  // } else {
  //   toast.error(t("unableToCall"));
  //   goBack();
  // }

  const onDelete = (id) => {
    reduxDispatch(setPromptLoading(true));
    userService
      .removeContact(id)
      .then((res) => {
        console.log(res);
        message.success('contact successfully removed');
        getContacts();
        reduxDispatch(togglePopup(false));
      })
      .catch((error) => {
        console.log(error);
        message.error(error?.errors || error?.message);
        getContacts();
      })
      .finally(() => {
        reduxDispatch(setPromptLoading(false));
      });
  };
  const confirmDelete = (user) => {
    reduxDispatch(setDrawer(false));
    reduxDispatch(togglePopup(true));
    reduxDispatch(
      setPopupChildren(
        <Prompt
          title="Remove Contact"
          body={`You're about to remove ${user?.firstName} ${user?.lastName} from your contact list. Are you sure?`}
          button="Remove"
          onVerify={() => onDelete(user?.id)}
          onClose={() => reduxDispatch(togglePopup(false))}
        />,
      ),
    );
  };
  return { getContacts, contacts, onCallUser, handleAddContact, state, dispatch, confirmDelete };
};

export default useContacts;
