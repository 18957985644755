import { Button, Input, Col, Row, Typography, Form, Divider } from 'antd';
import { useDispatch } from 'react-redux';
import useContacts from '../hooks/useContacts';
import '../styles.css';
import { setPopupChildren, togglePopup } from '../../../redux/globals/actions';
import ImportContact from '../ImportContact/ImportContact';
const { Text } = Typography;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};
/* eslint-enable no-template-curly-in-string */

const onFinish = (values) => {};

const AddContact = () => {
  const { handleAddContact, state, dispatch } = useContacts();
  const { email, name, addingContact } = state;
  const reduxDispatch = useDispatch();
  return (
    <Form
      {...layout}
      layout="vertical"
      name="nest-messages"
      onFinish={handleAddContact}
      validateMessages={validateMessages}
      style={{
        width: '400px',
        backgroundColor: '#fff',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      className="rounded-xl p-4 px-8 sm:w-[80vw]"
    >
      <div className="flex flex-row items-center justify-center font-normal text-xl py-3 mb-6 border-b-1">
        Add New Contact
      </div>
      <p className="text-center text-sm">
        Easily expand your network by adding or inviting your colleagues as your contacts.
      </p>
      <Row gutter={16}>
        <Col lg={24} sm={24} xs={24}>
          <Form.Item
            name={['user', 'name']}
            label="What's the name?"
            className="[&>div>div>label]:text-sm [&>div>div>label]:text-gray-500 dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input value={name} onChange={({ target }) => dispatch({ name: target.value })} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={24} sm={24} xs={24}>
          <Form.Item
            name={['user', 'email']}
            label="What's the email address"
            className="[&>div>div>label]:text-sm [&>div>div>label]:text-gray-500 dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium"
            rules={[
              {
                required: true,
              },
              {
                type: 'email',
              },
            ]}
          >
            <Input value={email} onChange={({ target }) => dispatch({ email: target.value })} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={24} sm={24} xs={24}>
          <Divider orientation="center m-0 tsxt-sm">OR</Divider>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={24} sm={24} xs={24} className="py-5">
          <Button
            size="large"
            onClick={() => {
              reduxDispatch(togglePopup(false));
              reduxDispatch(togglePopup(true));
              reduxDispatch(setPopupChildren(<ImportContact type="contact" />));
            }}
            type="light"
            className="bg-transparent hover:bg-white hover:text-primary w-full border-solid border-1 border-primary text-primary text-sm font-semibold leading-[22px] inline-flex items-center justify-center px-[20px] py-[5px]"
          >
            Import a list of contact
          </Button>
        </Col>
      </Row>
      <Row gutter={16} className="flex justify-between border-t-1 pt-3">
        <Button
          className="bg-primary hover:bg-white hover:text-primary border-solid border-1 border-primary text-white dark:text-white87 text-sm font-semibold leading-[22px] inline-flex items-center justify-center rounded-full px-[20px] py-[5px]"
          type="primary"
          size="large"
          htmlType="submit"
          loading={addingContact}
        >
          {addingContact ? 'Adding...' : 'Add'}
        </Button>

        <Button
          size="large"
          onClick={() => reduxDispatch(togglePopup(false))}
          type="light"
          className="bg-regularBG hover:bg-white hover:text-gray-500 border-solid border-1 text-gray-500 hover:border-gray-500 dark:text-white87 text-sm font-semibold leading-[22px] inline-flex items-center justify-center rounded-full px-[20px] py-[5px]"
        >
          Cancel
        </Button>
      </Row>
    </Form>
  );
};

export default AddContact;
