import { AutoComplete, Button, Typography, Input, Col, Row, Dropdown, Menu, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { PhoneFilled, VideoCameraFilled, MessageFilled } from '@ant-design/icons';
import { MdOutlineArrowBackIos, MdOutlineMoreVert } from 'react-icons/md';
import { BsFileEarmarkArrowDown, BsPersonPlusFill } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import useContacts from './hooks/useContacts';
import { MdSend, MdAttachFile } from 'react-icons/md';
import { setPopupChildren, togglePopup } from '../../redux/globals/actions';
import ScheduleMeeting from './ScheduleMeeting/ScheduleMeeting';
import useContactsMessages from './hooks/useContactsMessages';
import AddContact from './AddContact';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
const { Text } = Typography;
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

const Contacts = ({ isCall, isVideo }) => {
  const reduxDispatch = useDispatch();
  const dispatch = useDispatch();
  const { getContacts, contacts, onCallUser, confirmDelete } = useContacts();

  const {
    handleUserMessage,
    openChat,
    setOpenChat,
    messagingUser,
    onTyping,
    onEmitTypingAction,
    textMessage,
    handleKeyDown,
    sendingText,
    onSubmitMessage,
    messagesInView,
    messageBodyRef,
    isTyping,
    setIsTyping,
    fileUploadProps,
    fileUploadBtnProps,
    onSendMedia,
    mediaMessage,
    sendingMedia,
  } = useContactsMessages();
  useEffect(() => {
    getContacts();
  }, []);
  const [filteredData, setFilteredData] = useState(contacts);

  useEffect(() => {
    setFilteredData(contacts);
  }, [contacts]);

  const onSearch = (event) => {
    const lowerCaseText = event.toLowerCase();
    if (!event) {
      setFilteredData(contacts);
      return;
    }
    const filteredItems = contacts?.filter((item) => {
      const firstName = item?.contact?.firstName?.toLowerCase();
      const lastName = item?.contact?.lastName?.toLowerCase();

      return (firstName && firstName.includes(lowerCaseText)) || (lastName && lastName.includes(lowerCaseText));
    });

    setFilteredData(filteredItems);
  };

  const scheduleMeeting = (userInfo) => {
    reduxDispatch(togglePopup(true));
    reduxDispatch(setPopupChildren(<ScheduleMeeting user={userInfo} />));
  };

  const [userName, setUserName] = useState('');

  return (
    <div className="h-full relative flex flex-col">
      <div className="flex flex-row items-center text-gray-300 justify-center font-bold text-[15px] py-3">Contacts</div>
      <div className="pt-3">
        <AutoComplete onSearch={onSearch} placeholder="Search your contacts" patterns className="w-full" />
      </div>
      {contacts ? (
        <ul className="mb-0 pt-3 overflow-y-auto flex-1 text-gray-300">
          {filteredData?.map((user, i) => (
            <li key={i} className="flex flex-row justify-between items-center my-3">
              <div className="inline-flex items-center">
                <span className="w-6 h-6 rounded-full text-center font-bold text-white text-[11px] p-1 bg-primary ltr:mr-3 rtl:ml-3">
                  {user?.contact?.firstName[0] || ':'}
                  {user?.contact?.lastName[0] || ')'}
                </span>
                {user?.contact?.firstName || ' '} {user?.contact?.lastName || ' '}
              </div>
              <div className="flex flex-row justify-evenly gap-2 items-center align-middle overflow-hidden">
                {!isCall && !isVideo && (
                  <Button
                    className="flex justify-center items-center w-6 h-7 text-green-500 hover:text-green-500 hover:bg-transparent hover:scale-125 bg-transparent border-0"
                    onClick={() => {
                      handleUserMessage(user?.contact);
                      setUserName(user?.contact?.firstName);
                    }}
                  >
                    <MessageFilled size={18} />
                  </Button>
                )}

                {!isVideo && (
                  <Button
                    onClick={() => {
                      onCallUser(user?.contact, 'voice');
                    }}
                    title={`Call ${user?.contact?.firstName}`}
                    className="flex justify-center items-center w-6 h-7 text-green-500 hover:text-green-500 hover:bg-transparent hover:scale-125 bg-transparent border-0"
                  >
                    <PhoneFilled className="w-3 h-5" />
                  </Button>
                )}

                {!isCall && (
                  <Button
                    onClick={() => {
                      onCallUser(user?.contact, 'video');
                    }}
                    title={`Video Call ${user?.contact?.firstName}`}
                    className="flex justify-center items-center w-6 h-7 text-green-500 hover:text-green-500 hover:bg-transparent hover:scale-125 bg-transparent border-0"
                  >
                    <VideoCameraFilled className="w-5 h-5" />
                  </Button>
                )}

                {!isCall && !isVideo && (
                  <Dropdown
                    className="p-0 m-0"
                    overlay={
                      <Menu>
                        <Menu.Item onClick={() => scheduleMeeting(user?.contact)} key="1">
                          Schedule a meeting
                        </Menu.Item>
                      </Menu>
                    }
                    placement="bottomRight"
                    arrow
                    trigger={['click']}
                  >
                    <a className="text-gray-300 mr-1" onClick={(e) => e.preventDefault()}>
                      <MdOutlineMoreVert className="w-5 h-5 p-0" />
                    </a>
                  </Dropdown>
                )}
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <div className="mb-0 pt-3 overflow-y-auto flex flex-col justify-center items-center flex-1">
          <p className="text-center text-gray-300 text-sm">You do not have any contact yet</p>
          <Button
            onClick={() => {
              dispatch(togglePopup(true));
              dispatch(setPopupChildren(<AddContact />));
            }}
            title="Add Contact"
            className="bg-primary hover:bg-white hover:text-primary border-solid border-1 border-primary text-white dark:text-white87 text-sm font-semibold leading-[22px] inline-flex items-center justify-center rounded-full px-[20px] py-[5px]"
          >
            <BsPersonPlusFill size={20} className="m-2" /> Add Contact
          </Button>
        </div>
      )}

      {openChat && (
        <div className="w-full absolute transition-all flex flex-col justify-between top-0 bottom-0 p-5 bg-[#e5e7eb] border-1 border-gray-400 rounded-xl">
          <div className="flex flex-col flex-1 overflow-y-auto">
            <div className="flex flex-row items-center justify-start font-normal text-sm py-3 mb-4 border border-b-1">
              <span className="">
                <Button
                  type="link"
                  title="Back"
                  onClick={() => {
                    setOpenChat(false);
                    setUserName('');
                  }}
                  className="m-0 bg-transparent flex items-center border-none dark:border-transparent dark:bg-transparent dark:text-white60 dark:hover:text-primary hover:bg-transparent"
                >
                  <MdOutlineArrowBackIos className="text-xl text-gray-300 font-semibold" />
                </Button>
              </span>
              <span className="flex-1 text-center">Messages with {userName}</span>
            </div>
            <div ref={messageBodyRef} className="mt-1 rounded-6  p-2 pt-6 overflow-auto flex-1">
              {messagesInView
                ?.slice(0)
                ?.reverse()
                ?.map((message, i) =>
                  message?.sender && message?.sender_deleted ? null : !message?.sender &&
                    message?.receiver_deleted ? null : message?.sender ? (
                    <div
                      className="flex flex-row mx-1 mb-8"
                      style={{
                        justifyContent: 'flex-end',
                      }}
                    >
                      <div className="max-w-xs p-2 bg-gray-300 rounded-tr-10 rounded-bl-10 relative">
                        <Text className="text-xs text-gray-300 mb-1 absolute -top-4">You</Text>
                        {message?.file != null ? (
                          <div className="flex w-full justify-center items-center gap-1 opacity-50">
                            <div className="border border-gray-800 h-8 w-8 rounded-full flex items-center justify-center">
                              <a
                                className="text-gray-800 h-8 w-8  flex items-center justify-center rounded-full"
                                href={message?.file}
                              >
                                <BsFileEarmarkArrowDown size={20} />
                              </a>
                            </div>
                            <div>
                              <Text
                                style={{
                                  fontSize: 12,
                                }}
                                className="text-gray-300"
                              >
                                ...{message?.file.substr(-13)}
                              </Text>
                            </div>
                          </div>
                        ) : null}
                        {message?.text !== '' && message?.text != null && (
                          <Text
                            style={{
                              color: '#000',
                              fontSize: 13,
                            }}
                          >
                            {message?.text}
                          </Text>
                        )}

                        <div className="flex flex-row items-center justify-end">
                          <Text
                            style={{
                              color: '#000',
                              fontSize: 10,
                              opacity: 0.5,
                            }}
                          >
                            {moment(message?.createdAt).format('h:mm a')}
                          </Text>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="flex flex-row mx-1 mb-8"
                      style={{
                        justifyContent: 'flex-start',
                      }}
                    >
                      <div className="max-w-xs p-2 bg-white rounded-tr-10 rounded-bl-10 relative">
                        <p className="text-xs text-gray-300 mb-1 absolute -top-4 text-ellipsis w-[100px]">
                          {messagingUser?.firstName}
                        </p>
                        {message?.file != null ? (
                          <div className="flex w-full justify-center items-center gap-1 opacity-50">
                            <div className="border border-gray-400 h-8 w-8 rounded-full flex items-center justify-center">
                              <a
                                className="text-gray-400 h-8 w-8  flex items-center justify-center rounded-full"
                                href={message?.file}
                              >
                                <BsFileEarmarkArrowDown size={20} />
                              </a>
                            </div>
                            <div>
                              <Text
                                style={{
                                  fontSize: 12,
                                }}
                                className="text-gray-300"
                              >
                                ...{message?.file.substr(-13)}
                              </Text>
                            </div>
                          </div>
                        ) : null}
                        {message?.text !== '' && message?.text != null && (
                          <Text
                            style={{
                              color: '#000',
                              fontSize: 13,
                            }}
                          >
                            {message?.text}
                          </Text>
                        )}

                        <div className="flex flex-row items-center justify-end">
                          <Text
                            style={{
                              color: '#000',
                              fontSize: 10,
                              opacity: 0.5,
                            }}
                          >
                            {moment(message?.createdAt).format('h:mm a')}
                          </Text>
                        </div>
                      </div>
                    </div>
                  ),
                )}
            </div>
          </div>

          <Row gutter={16} className="flex justify-between border-t-1 pt-3">
            {isTyping && (
              <Text className="text-gray-400 rounded-tr-10 rounded-bl-10 text-xs ml-4 mb-1">{`${messagingUser?.firstName} is typing...`}</Text>
            )}
            <div className="ml-6  file-container">
              <Upload {...fileUploadProps} />
            </div>

            <Col span={24} className="flex gap-1">
              <Input
                className="rounded-full"
                placeholder="Message"
                onFocus={() => onEmitTypingAction(messagingUser.id, true)}
                onBlur={() => onEmitTypingAction(messagingUser.id, false)}
                onChange={(e) => onTyping(e.target.value)}
                onKeyDown={(e) => handleKeyDown(e, messagingUser?.id)}
                value={textMessage}
              />
              <Upload {...fileUploadBtnProps} disabled={sendingMedia}>
                <Button
                  className="bg-primary hover:bg-white hover:text-primary border-solid border-1 border-primary text-white dark:text-white87 text-[14px] font-semibold items-center rounded-full p-[13px] w-12 h-12"
                  type="primary"
                  size="small"
                  onClick={() => null}
                  disabled={sendingMedia}
                >
                  {sendingMedia ? (
                    <Spin className=" hover:text-primary text-white dark:text-white87" indicator={antIcon} size={16} />
                  ) : (
                    <MdAttachFile size={16} />
                  )}
                </Button>
              </Upload>
              <Button
                className="bg-primary hover:bg-white hover:text-primary border-solid border-1 border-primary text-white dark:text-white87 text-[14px] font-semibold items-center rounded-full p-[13px] w-[52px] h-12"
                type="primary"
                size="small"
                disabled={sendingText || sendingMedia}
                onClick={(e) => {
                  e.preventDefault();
                  mediaMessage ? onSendMedia() : onSubmitMessage(messagingUser?.id);
                }}
              >
                <MdSend size={16} />
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default Contacts;
