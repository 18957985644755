import env from '../env';
import { request } from './api';
const { API_URL2 } = env;

const URL = {
  getMe: `${API_URL2}/users/me`,
  login: `${API_URL2}/users/login`,
  verifyEmail: `${API_URL2}/users/otp`,
  twoFA: `${API_URL2}/users/login/verify`,
  verifyPassword: `${API_URL2}/users/verify-password`,
  resetPassword: `${API_URL2}/users/password`,
  forgotPassword: `${API_URL2}/users/forgot-password`,
  createMe: `${API_URL2}/users`,
  createInstitution: `${API_URL2}/institution`,
  selectAccount: `${API_URL2}/institution`,
};

function getMe() {
  return request(URL.getMe, {}, 'GET');
}

function verifyEmail(email, otp) {
  return request(URL.verifyEmail, { email, otp }, 'POST');
}

function verifyPassword({ email, otp }) {
  return request(URL.verifyPassword, { email, otp }, 'POST');
}
function twoFA({ email, otp }) {
  return request(URL.twoFA, { email, otp }, 'POST');
}

function resetPassword(password) {
  return request(URL.resetPassword, { type: 'FORGOT', password }, 'PATCH');
}
function createMe(params) {
  return request(URL.createMe, { ...params }, 'POST');
}
function createInstitution(params) {
  return request(URL.createInstitution, { ...params }, 'POST');
}

function resendOtp({ email }) {
  return request(URL.verifyEmail, { email }, 'GET');
}

function forgotPassword(email) {
  return request(URL.forgotPassword, { email }, 'POST');
}
function login(params) {
  return request(URL.login, { ...params }, 'POST');
}
function selectAccount(params) {
  return request(`${URL.selectAccount}/${params}/doctor`, {}, 'POST');
}

const authenticationService = {
  getMe,
  verifyEmail,
  verifyPassword,
  forgotPassword,
  resetPassword,
  createMe,
  resendOtp,
  login,
  createInstitution,
  twoFA,
  selectAccount,
};

export default authenticationService;
