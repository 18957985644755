import { Col, Layout, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MenueItems from './MenueItems';
import { FooterStyle, LayoutContainer } from './Style';
import AppHeader from './AppHeader';
import ControlPanel from '../components/Call/components/ControlPanel';
import { Drawer } from '../components/drawer/drawer';
import CallView from '../components/Call/components/CallView';
import io from 'socket.io-client';
import useCall from '../components/Call/hooks/useCall';
import ModalPopup from '../components/ModalPopup';
import AdminMenuItems from './AdminMenuItems';
import useSocketPeer from './hooks/useSocketPeer';

const { Sider, Content } = Layout;

const AdminLayout = ({ props, children }) => {
  const location = useLocation();
  const splitPath = location?.pathname?.split('/')[2];
  const { onCall } = useSelector((state) => state.globals);
  const togglePane = useSelector((state) => state.app.toggle);

  const [isAdmin, setIsAdmin] = useState(location?.pathname.startsWith('/app/admin'));

  useEffect(() => {
    setIsAdmin(location?.pathname.startsWith('/app/admin'));
  }, [location.pathname]);

  const {
    getDeviceStream,
    localStreamRef,
    toggleMic,
    toggleVideo,
    state,
    remoteStreamRef,
    shareScreen,
    stopSharingScreen,
    maximizeRemoteVideo,
    endCall,
  } = useCall();
  useSocketPeer();

  return (
    <LayoutContainer id="app-layout">
      <Layout className="layout">
        {!isAdmin && <ControlPanel endCall={endCall} />}
        {onCall && (
          <CallView
            getDeviceStream={getDeviceStream}
            localStreamRef={localStreamRef}
            toggleMic={toggleMic}
            toggleVideo={toggleVideo}
            state={state}
            remoteStreamRef={remoteStreamRef}
            shareScreen={shareScreen}
            stopSharingScreen={stopSharingScreen}
            maximizeRemoteVideo={maximizeRemoteVideo}
            endCall={endCall}
          />
        )}
        <Drawer />
        <ModalPopup />
        <Layout className={`${isAdmin ? 'bg-[#343943]' : ''}`}>
          <AppHeader />
          {splitPath != 'viewer' && (
            <Sider
              width={250}
              style={{
                margin: '48px 0 0 0',
                padding: '30px 0 55px 20px',
                overflowY: 'auto',
                height: '90.34vh',
                position: 'fixed',
                left: 0,
                zIndex: 988,
                // backgroundColor: '#010413',
              }}
              collapsed={togglePane}
              // className="border border-gray-600"
            >
              {isAdmin ? <AdminMenuItems /> : <MenueItems />}
            </Sider>
          )}

          <Layout
            className={`${splitPath != 'viewer' ? 'atbd-main-layout' : 'mt-14'} ${isAdmin ? 'bg-[#343943]' : ''}`}
          >
            <Content>
              {children}
              {splitPath != 'viewer' && (
                <FooterStyle
                  className={`${isAdmin ? 'bg-[#1B1E2B]' : 'bg-white border'} fixed bottom-0 left-0 z-998 py-2`}
                >
                  <Row>
                    <Col md={24} xs={24} span={24}>
                      <span
                        className={`inline-block w-full font-medium admin-footer__copyright md:text-center text-right ${
                          isAdmin ? 'text-white60' : 'text-theme-gray'
                        }`}
                      >
                        © 2021 - {new Date().getFullYear()}
                        <a href="https://xolanihealth.com" target="_blank" className="mx-[4px] text-primary">
                          Xolani Health Inc.
                        </a>
                      </span>
                    </Col>
                  </Row>
                </FooterStyle>
              )}
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </LayoutContainer>
  );
};

export default AdminLayout;
