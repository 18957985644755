import { Button, Layout, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as MySVG } from '../static/img/icon/left-bar.svg';
import logoImg from '../static/img/dicomx-logo-new.png';
import { setDrawer, setDrawerChildren } from '../redux/globals/actions';
import Contacts from '../components/Contacts';
import { MdGroups, MdPhoneEnabled, MdVideocam } from 'react-icons/md';
import { useEffect, useState } from 'react';
import { setMeHospital, setMeRadiologist, setMyData } from '../redux/users/actions';
import authenticationService from '../services/authentication.service';
import radiologistsService from '../services/radiologists.service';
import institutionService from '../services/institution.service';
import { setToggle } from '../redux/app/actions';
import { MdOutlineArrowBackIos } from 'react-icons/md';
import './style.css';

const { Header } = Layout;
const AppHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const splitPath = location.pathname.split('/');

  const myData = useSelector((state) => state.user.myData);
  const togglePane = useSelector((state) => state.app.toggle);
  const [normalSize, setNormalSize] = useState(true);

  const getMyData = () => {
    authenticationService
      .getMe()
      .then(({ data }) => {
        dispatch(setMyData(data));
        console.log(data);
        if (data?.practitioner) {
          getMeRadiologist();
        }
        if (data?.type === 'INSTITUTION') {
          getMeHospital();
        }
        if (!data?.verified) {
          navigate('/app/auth/verify');
        }
      })
      .catch((error) => {
        console.log(error);
        message.error(error.errors);
        if (error?.message === 'No token found' || error?.errors === 'No token found') {
          navigate('/app/auth');
        }
      });
  };
  const getMeRadiologist = () => {
    radiologistsService
      .getMe()
      .then(({ data }) => {
        dispatch(setMeRadiologist(data));
      })
      .catch((error) => {
        console.log(error);
        message.error(error?.errors || error?.message);
      });
  };
  const getMeHospital = () => {
    institutionService
      .getMe()
      .then(({ data }) => {
        dispatch(setMeHospital(data));
      })
      .catch((error) => {
        console.log(error);
        message.error(error?.errors || error?.message);
      });
  };

  useEffect(() => {
    getMyData();
  }, [window.location.pathname]);

  // useEffect(() => {
  //   if (myData?.practitioner) {
  //     getMeRadiologist();
  //   }
  // }, [myData,window.location.pathname ]);
  // useEffect(() => {
  //   if (myData?.type === 'INSTITUTION') {
  //     getMeHospital();
  //   }
  // }, [myData, window.location.pathname]);

  useEffect(() => {
    if (window.innerWidth <= 991) {
      dispatch(setToggle(true));
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (window.innerWidth >= 991) {
      setNormalSize(true);
    }
    if (window.innerWidth <= 991) {
      setNormalSize(false);
    }
  }, [window.innerWidth]);

  return (
    <Header
      style={{
        position: 'fixed',
        width: '100%',
        top: 0,
        right: 0,
      }}
      className={`flex border  ${
        location?.pathname.startsWith('/app/admin')
          ? 'bg-[#323541] shadow-[0_5px_20px_rgba(160,160,160,.02)] border-none'
          : 'bg-[#323541] border-none'
      } h-[45px] z-998`}
    >
      <div className="flex flex-row items-center flex-1 h-full">
        <div className=" rtl:ssm:pr-[15px] ltr:pr-5 rtl:pl-5 ltr:ssm:pl-[15px] ltr:ssm:pr-[15px] rtl:ssm::pl:[15px] ltr:pl-[30px] rtl:pr-[30px] xs:ltr:pl-[20px] xs:rtl:pr-[20px] min-w-[280px] ssm:min-w-[220px] xs:min-w-[170px] h-full grid align-middle dark:bg-[#323541] sm:w-full">
          <div className="flex items-center justify-between  sm:justify-between sm:w-full">
            {!normalSize ? (
              <div className="flex flex-row items-center">
                <div className="flex gap-2 top-right-wrap">
                  <Button
                    onClick={() => {
                      dispatch(setDrawer(true));
                      dispatch(setDrawerChildren(<Contacts isCall />));
                    }}
                    title="Voice Call"
                    className="rounded-full flex flex-col justify-center items-center w-8 h-8 bg-green-500 hover:bg-green-500 hover:scale-110 border-0 text-white"
                  >
                    <MdPhoneEnabled size={20} />
                  </Button>
                  <Button
                    onClick={() => {
                      dispatch(setDrawer(true));
                      dispatch(setDrawerChildren(<Contacts isVideo />));
                    }}
                    title="Video Call"
                    className="rounded-full flex flex-col justify-center items-center w-8 h-8 bg-green-500 hover:bg-green-500 hover:scale-110 text-white border-0"
                  >
                    <MdVideocam size={20} />
                  </Button>
                  <Button
                    title="Contacts"
                    className="rounded-full flex flex-col justify-center items-center w-8 h-8 bg-primary hover:bg-primary hover:scale-110 text-white border-0"
                    onClick={() => {
                      dispatch(setDrawer(true));
                      dispatch(setDrawerChildren(<Contacts />));
                    }}
                  >
                    <MdGroups size={20} />
                  </Button>
                </div>
              </div>
            ) : null}

            <div className="flex">
              {splitPath[2] === 'viewer' && (
                <Button
                  type="link"
                  title="Back to study"
                  onClick={() => navigate(-1)}
                  className="pr-5 m-0 bg-transparent flex items-center border-none dark:border-transparent dark:bg-transparent dark:text-white60 dark:hover:text-primary text-[#525768] hover:text-primary hover:bg-transparent"
                >
                  <MdOutlineArrowBackIos className="text-2xl text-primary font-semibold" />
                </Button>
              )}

              {normalSize && (
                <Link to={location?.pathname.startsWith('/app/admin') ? '/app/admin' : '/app'}>
                  <img className="w-full max-w-[100px] xs:max-w-[100px] p-0 m-0" src={logoImg} alt="Xolani Bridge" />
                </Link>
              )}
            </div>

            {splitPath[2] !== 'viewer' && (
              <Button
                type="link"
                onClick={() => dispatch(setToggle(!togglePane))}
                className="p-0 bg-transparent border-none dark:border-transparent dark:bg-transparent dark:text-white60 dark:hover:text-primary text-[#525768] hover:text-primary hover:bg-transparent"
              >
                <MySVG />
              </Button>
            )}
          </div>
        </div>

        {!location?.pathname.startsWith('/app/admin') && (
          <div className="flex items-center justify-end gap-8 px-8 flex-auto ltr:mr-[10px] rtl:ml-[10px] [&>div:first-child]:flex [&>div]:items-center sm:hidden">
            {normalSize ? (
              <div className="flex flex-row items-center ">
                <div className="flex gap-2 top-right-wrap">
                  <Button
                    onClick={() => {
                      dispatch(setDrawer(true));
                      dispatch(setDrawerChildren(<Contacts isCall />));
                    }}
                    title="Voice Call"
                    className="rounded-full flex flex-col justify-center items-center w-8 h-8 bg-green-500 hover:bg-green-500 hover:scale-110 border-0 text-white"
                  >
                    <MdPhoneEnabled size={20} />
                  </Button>
                  <Button
                    onClick={() => {
                      dispatch(setDrawer(true));
                      dispatch(setDrawerChildren(<Contacts isVideo />));
                    }}
                    title="Video Call"
                    className="rounded-full flex flex-col justify-center items-center w-8 h-8 bg-green-500 hover:bg-green-500 hover:scale-110 text-white border-0"
                  >
                    <MdVideocam size={20} />
                  </Button>
                  <Button
                    title="Contacts"
                    className="rounded-full flex flex-col justify-center items-center w-8 h-8 bg-primary hover:bg-primary hover:scale-110 text-white border-0"
                    onClick={() => {
                      dispatch(setDrawer(true));
                      dispatch(setDrawerChildren(<Contacts />));
                    }}
                  >
                    <MdGroups size={20} />
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </Header>
  );
};

export default AppHeader;
